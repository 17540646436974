.filter-icon {
  background: #d9d9d9;
  border-radius: 3px;
  color: #219ebc;
  font-size: 36px;
  padding: 10px;
  margin-left: 15px;
  margin-top: -5px;
}
.influncer-campaign-section {
  padding-top: 80px;
  padding-bottom: 50px;
}
.influncer-campaign-section h2 {
  color: #219ebc;
  margin-bottom: 80px;
}
.influncer-cards {
  width: 90%;
  margin: auto;
  margin-top: 100px;
}
.brand-logo {
  width: 130px !important;
  height: 130px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  background: #fff;
  border-radius: 20px;
  padding: 9px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
@media screen and (max-width: 768px) {
  .browse-bttn button {
    width: 90%;
    overflow: hidden;
    margin-bottom: 20px;
  }
  .filter-icon {
    margin-left: 0px;
  }
}
