.nft-details-container {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding-top: 150px;
  margin-bottom: 50px;
}
.nft-details-left {
  width: 80%;
}
.nft-details-left img {
  width: 100%;
}
.nft-details-right {
  width: 90%;
  margin-left: 3%;
}
.maggie-container {
  background: #ffffff;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.maggie-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}
.maggie-heading h4 {
  text-align: left !important;
  text-transform: capitalize !important;
  color: #219ebc !important;
  position: relative;
  font-size: 26px;
  margin: 0;
  margin-left: 20px;
  margin-bottom: 5px;
}
.maggie-heading h4::before {
  content: '';
  position: absolute;
  background: #f8991d;
  width: 5px;
  height: 22px;
  top: 6px;
  left: -20px;
}
.maggie-heading div > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.maggie-heading div > div > h6 {
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0.2px;
  color: #023047;
  opacity: 0.75;
  margin: 0;
}
.maggie-heading div > div > span {
  color: #219ebc;
  opacity: 0.75;
}
.maggie-heading div > div > p {
  color: #023047;
  opacity: 0.75;
}
.maggie-heading div:nth-child(1) {
  width: 50%;
}
.action-icons {
  display: flex;
  width: 20%;
  justify-content: space-between;
  background: #f6f8fb;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}
.action-icons svg {
  font-size: 36px;
  border-right: 1px solid #d9d9d9;
  padding: 5px 10px;
}
.action-icons svg:last-child {
  border-right: 0px;
}
.impact-initiative {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}
.impact-initiative > div {
  background: #f6f8fb;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 15px 15px;
  width: 60%;
  margin-left: 20px;
}
.impact-initiative h5 {
  color: #f8991d;
  font-weight: 600;
}
.impact-initiative ul {
  margin-top: 15px;
  padding: 0;
}
.impact-initiative ul li {
  letter-spacing: -0.02em;
  color: rgba(0, 0, 0, 0.5);
  font-size: 17px;
  margin-top: 5px;
  list-style: none;
}
.impact-initiative ul li::before {
  content: '• ';
  color: #fce887;
  font-size: 22px;
}
.impact-initiative ul li span {
  font-weight: 600;
  color: #000000;
}
.sales-ends {
  padding-left: 20px;
  padding-right: 20px;
  width: 60%;
}
.sales-ends p,
.current-price p {
  letter-spacing: 0.2px;
  color: #023047;
  opacity: 0.75;
}
.sales-ends > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sales-hours {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sales-hours h5 {
  color: #f8991d;
  opacity: 0.75;
  font-size: 24px;
  font-weight: 700;
  margin-right: 60px;
}
.sales-hours p {
  color: #023047;
  opacity: 0.75;
  font-size: 16px;
}
.current-price {
  margin-top: 20px;
  padding: 20px;
  border-top: 1px solid #d9d9d9;
}
.current-price h4 {
  color: #f8991d;
  opacity: 0.75;
  text-align: left;
  font-size: 26px;
}
.current-price button:nth-child(1) {
  background: #aeaeae !important;
}
.record-listing-accordion {
  margin-top: 50px;
}
.record-listing-accordion > div {
  margin-bottom: 20px;
}
.record-listing-accordion .MuiAccordion-root {
  border-radius: 10px !important;
}
.record-listing-accordion .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  border-radius: 10px !important;
  border: 2px solid #aeaeae !important;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
}
.MuiAccordion-root p {
  font-size: 14px !important;
  text-transform: uppercase;
}
.MuiAccordion-root p svg {
  margin-right: 10px !important;
}

@media screen and (max-width: 768px) {
  .nft-details-container {
    display: block;
    padding-left: 15px;
    padding-right: 15px;
  }
  .nft-details-left {
    width: 100%;
    margin-bottom: 50px;
  }
  .nft-details-right {
    width: 100%;
    margin-left: 0%;
  }
  .maggie-heading {
    padding-left: 10px;
    padding-right: 10px;
  }
  .maggie-heading div > div > h6,
  .maggie-heading div > div > span,
  .maggie-heading div > div > p {
    font-size: 12px;
  }
  .action-icons svg {
    padding: 2px 6px;
  }
  .impact-initiative > div,
  .sales-ends,
  .sales-ends > div {
    width: 89%;
  }
  .sales-hours h5 {
    margin-right: 30px;
  }
  .current-price button {
    margin-bottom: 10px;
  }
}
