.nft-market-tabs {
  background-color: #023047 !important;
  color: white !important;
}
.nft-market-tabs .Mui-selected {
  background-color: white !important;
  color: black !important;
}
.nft-market-tabs button {
  border: 1px solid #fff !important;
  color: #fff !important;
  border-radius: 100px !important;
  margin-left: 30px;
  margin-right: 30px;
  flex: 1 !important;
  text-transform: none !important;
}
.nft-market-tabs .MuiTabs-indicator {
  display: none !important;
}
.nft-market-tabpanel {
  width: 100%;
}
.nft-market-tabpanel {
  padding: 0px;
}
.nft-market-tabpanel {
  background: #fff;
}
.nft-market-tabpanel h2 {
  margin-top: 10px;
  margin-bottom: 40px;
}
.info-questions-container {
  background: #023047;
  padding-top: 20px;
  padding-bottom: 20px;
}
.info-questions-container > div {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info-questions-container img {
  width: 15%;
  margin-right: 25px;
}
.info-questions-container a {
  font-weight: 700;
  text-decoration: none;
}
.info-questions-container div > div {
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 20px 14px 36px 14px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 25px 30px;
  width: 90%;
}
.info-questions-container div > div:nth-child(1) {
  margin-right: 20px;
}
.info-questions-container div > div:nth-child(2) {
  margin-left: 20px;
}
.info-questions-container div > div:nth-child(1) > h5 > a {
  color: #f8991d;
}
.info-questions-container div > div:nth-child(2) > h5 > a {
  color: #219ebc;
}
.join-community-container {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 50px;
}
.join-community-container > div:nth-child(1) {
  background: #ffffff;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 80%;
  margin-right: 20px;
  padding: 100px 125px;
}
.join-community-container > div:nth-child(2) {
  width: 90%;
  margin-left: 20px;
}
.join-community-container img {
  width: 100%;
}
.join-community-container h2 {
  text-align: left;
  color: #219ebc;
  position: relative;
  margin-left: 30px;
}
.join-community-container h2::before,
.featured-influencer-container h2::before {
  content: '';
  position: absolute;
  top: 5px;
  background: #f8991d;
  height: 100%;
  width: 4px;
  left: -20px;
}
.join-community-container button {
}
.bttn-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.featured-influencer-section {
  padding: 50px 30px;
}
.featured-influencer-container {
  background: #ffffff;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.featured-influencer-container h2 {
  color: #f8991d;
  text-align: left;
  position: relative;
  margin-left: 20px;
}

.featured-influencer-container h6 {
  color: #ffffff;
  text-transform: uppercase;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 80px;
}
.featured-influencer-container > div:nth-child(2) {
  width: 50%;
  background: #023047;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 80px 125px;
}
.featured-influencer-container > div:nth-child(1) {
  background: #ffffff;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 30px;
  width: 40%;
}
.featured-video img {
  width: 100%;
}
.button-aligns{
  display: flex;
}

@media screen and (max-width: 1200px) {
  .nftCampaignContainer {
    flex-direction: column-reverse !important;
  }
  .featured-video {
    margin-bottom: 1em !important;
  }

  .featured-video img {
    width: 100%;
    height: 200px;
    object-fit: contain;
    background-color: #000;
  }
}

@media screen and (max-width: 768px) {
  /* .nft-market-banner {
    height: 30vh !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    background-position: center !important;
    background-size: cover !important;
  } */
  .heroContainer {
    height: 33vh;
  }
  .heroContainer video{
    width: 100%    !important;
    height: auto   !important;
  }
  .nft-market-tabs {
    width: 100%;
  }
  .nft-market-tabs > div {
    padding: 10px;
  }
  .nft-market-tabs button {
    font-size: 10px;
    padding: 6px;
  }
  .featured-influencer-container {
    display: block;
    padding: 15px;
  }
  .featured-influencer-container > div:nth-child(2) {
    width: 100%;
    padding: 50px 40px;
    margin-bottom: 30px;
  }
  .featured-influencer-container h6 {
    font-size: 12px;
    padding-bottom: 30px;
  }
  .featured-influencer-btn button {
    font-size: 12px;
  }
  .featured-video video {
    width: 350px;
    height: 350px;
  }
  .featured-video {
    margin-bottom: 30px;
  }
  .heading-font{
    font-size: 30px !important;
    padding-bottom: 20px !important;
  }
  .brand-card-spacing{
    margin-bottom: 60px !important;
  }
  .button-container{
    text-align: center !important;
  }
  .button-container button{
    /* display: block; */
    margin-bottom: 15px;
  }
  .button-aligns{
    display: block;
  }
  .nft-marketplace-tabs button{
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .button-margins button{
    margin-left: 0px !important;
  }
  .nft-tabs-container{
    padding-left: 0px !important;
    padding-right: 0px !important;
    /* margin-bottom: -80px; */
  }
  .info-questions-container > div {
    display: block;
    width: 90%;
  }
  .info-questions-container div > div {
    width: 100%;
  }
  .info-questions-container div > div:nth-child(1) {
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .info-questions-container div > div:nth-child(2) {
    margin-left: 0px;
  }
  .join-community-container {
    display: block;
  }
  .join-community-container > div:nth-child(1) {
    width: 100%;
    margin-right: 0px;
    padding: 60px 20px;
    margin-bottom: 50px;
  }
  .join-community-container > div:nth-child(2) {
    width: 100%;
    margin-left: 0px;
  }
  .info-questions-container a {
    font-size: 16px;
  }
  .featured-influencer-container > div:nth-child(1) {
    padding: 20px;
  }
  .nft-banner video {
    /* max-width: 100%;
    height: 100% !important; */
    overflow-x: hidden;
  }
  .container-padding{
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  /* #showCaseContainer{
    margin-top: px !important;
    margin-bottom: 20px !important;
  } */
  .featured-influencer-video-gif{
    height: 30vh !important;
  }
}
