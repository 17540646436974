.startup-banner p {
  width: 80%;
  font-weight: 400;
  font-size: 36px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 0.2px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 80px;
}
.startup-bttn {
  background: #023047 !important;
}
.block-chain-technology img {
  width: 100%;
}
.what-we-do-container {
  background: #023047;
  padding: 80px 0;
}
.what-we-do-heading {
  position: relative;
  width: 50%;
  margin: auto;
  padding: 70px 130px;
  background: #012536;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow: hidden;
}
.what-we-do-heading::before {
  content: '';
  position: absolute;
  border: 1px solid #f8991d;
  right: 3%;
  top: -2%;
  left: -2%;
  bottom: 5%;
}
.what-we-do-heading h2 {
  color: #219ebc;
}
.what-we-do-heading p {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #f6f8fb;
}
.development-design-container {
  width: 50%;
  margin: auto;
  display: flex;
  margin-top: 50px;
  margin-bottom: 50px;
}
.development-design-container > div {
  position: relative;
  background: #ffffff;
  box-shadow: 20px 14px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow: hidden;
}
.development-design-container > div::before {
  content: '';
  position: absolute;
  border: 1px solid #023047;
  right: 3%;
  top: -2%;
  left: -2%;
  bottom: 5%;
}
.development-design-container > div:nth-child(1),
.development-design-container > div:nth-child(2) {
  width: 100%;
  padding: 40px;
}
.development-design-container > div:nth-child(1) {
  margin-right: 15px;
}
.development-design-container > div:nth-child(2) {
  margin-left: 15px;
}
.development-design-container p {
  font-weight: 500;
  font-size: 22px;
  line-height: 34px;
  text-align: center;
  color: #023047;
  padding-bottom: 30px;
}
.plan-build-container {
  background: #1f789f;
  padding: 80px 15px;
}
.single-grid > div {
  background: #fff;
  text-align: center;
  border-top: 6px solid #f8991d;
  height: 315px;
}
.single-grid h4 {
  margin: 0;
  font-weight: 400;
  font-size: 26px;
  line-height: 54px;
  color: #f4f6f9;
  background: #f8991d;
  padding: 0px 15px;
}
.single-grid p {
  font-size: 20px;
  line-height: 30px;
  color: #f4f6f9;
  padding: 20px 18px;
  background: #219ebc;
  padding-bottom: 80px;
  height: 220px;
}
.impact-benefits-container {
  background: #f6f8fb;
  padding-top: 30px;
  padding-bottom: 30px;
}
.benefits-section {
  position: relative;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
  background: #ffffff;
  box-shadow: 20px 14px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding-top: 30px;
  padding-bottom: 100px;
  padding-left: 80px;
  padding-right: 80px;
  overflow: hidden;
}
.benefits-section::before {
  content: '';
  position: absolute;
  border: 1px solid #f8991d;
  right: 3%;
  top: -2%;
  left: -2%;
  bottom: 5%;
}
.single-benefit {
  text-align: center;
}
.single-benefit img {
  width: 60%;
  height: 180px;
  object-fit: contain;
}
.img-width1 img {
  width: 50%;
  height: 150px !important;
}
.img-width2 img {
  width: 40%;
  height: 150px !important;
}
.img-width3 img {
  height: 150px !important;
}
.requirments-section {
  padding-top: 80px;
  padding-bottom: 100px;
}
.requirments-content {
  width: 90%;
  margin: auto;
  background: #219ebc;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding-top: 40px;
  padding-bottom: 20px;
  padding-left: 5%;
  padding-right: 5%;
}
.requirments-content h2 {
  color: #fff !important;
}
.requirments-content ul li {
  font-weight: 400;
  font-size: 24px;
  line-height: 60px;
  letter-spacing: 0.2px;
  color: #f4f6f9;
  list-style: none;
  display: flex;
  align-items: center;
}
.requirments-content ul li::before {
  content: '• ';
  color: #fce887;
  font-size: 60px;
  margin-right: 20px;
  margin-bottom: 5px;
}

@media screen and (max-width: 992px) {
  .startup-banner h1 {
    padding-bottom: 0px !important;
    margin-bottom: 20px !important;
  }
  .startup-banner p {
    width: 100%;
    font-size: 18px;
    padding-left: 15px;
    padding-right: 15px;
    line-height: 26px;
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .startup-bttn {
    margin-bottom: 30px !important;
  }
  .block-chain-technology img {
    height: 30vh;
  }
  .what-we-do-heading {
    width: 90%;
    padding: 50px;
  }
  .development-design-container {
    width: 90%;
  }
  .development-design-container p {
    font-size: 16px;
    line-height: 20px;
  }
  .development-design-container > div:nth-child(1),
  .development-design-container > div:nth-child(2) {
    padding: 15px;
  }
  .benefits-section {
    margin-left: 15px;
    margin-right: 15px;
  }
  .development-design-container h4 {
    font-size: 20px;
  }
  .single-benefit img {
    height: auto !important;
  }
  .img-width1 img {
    height: auto !important;
  }
  .img-width2 img {
    height: auto !important;
  }
  .img-width3 img {
    height: auto !important;
  }
  .requirments-content {
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .requirments-content ul li,
  .requirments-content ul li a {
    font-size: 16px;
    line-height: 35px;
  }
  .requirments-content ul li {
    display: block;
  }
  .requirments-content ul li::before {
    font-size: 30px;
    margin-right: 21px;
  }
  .requirments-content ul {
    margin-top: 40px;
    margin-right: 15px;
    margin-left: -15px;
  }
}
