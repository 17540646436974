.login-confirmation-section {
  overflow: hidden;
  background: url('../../assets/loginBackground.png');
  background-repeat: no-repeat;
  background-size: 50% 100%;
  height: 110vh;
}
.login-confirmation-container {
  width: 90%;
  margin: 0 auto;
}
.confirmation-left {
}
.confirmation-left img {
  margin-top: 20%;
  margin-bottom: 40px;
}
.confirmation-left p {
  width: 637px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  padding-right: 30px;
}
.lets-get-started {
  display: flex;
  margin-top: 180px;
  justify-content: center;
}
.lets-get-started p {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 27px;
  line-height: 40px;
  color: #ffffff;
  text-align: center;
}
.forward-arrow {
  margin-right: 20%;
  font-size: 44px;
  color: #fff;
}
.reset-password-container {
  background-color: #f7f7f7;
  width: 75%;
  margin: 0 auto;
  margin-top: 35px;
  padding: 50px 85px;
  border-radius: 20px;
  height: 90vh;
  text-align: center;
}
.responsive-logo {
  display: none;
}
.reset-password-container img {
  width: 90px;
  height: 90px;
}
.reset-password-container h5 {
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 27px;
  line-height: 40px;
  color: #0f0103;
  margin-top: 15px;
}
.reset-password-container p {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #0f0103;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 30px;
}
.reset-password-container > button {
  width: 100%;
  height: 48px;
  background: #219ebc;
  border: none;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #ffffff;
  border-radius: 6px;
  margin-bottom: 18px;
}
.reset-password-container span {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #2d3748;
}
.back-to-login {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  cursor: pointer;
}
.back-to-login span {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #474848;
}
.back-arrow {
  margin-right: 10px;
}

/* Responsive*/

@media screen and (max-width: 1400px) {
  .responsive-logo {
    display: none;
  }
  .login-confirmation-section {
    height: 100vh;
  }
  .confirmation-left img {
    width: 80%;
  }
  .confirmation-left p {
    width: 474px;
    font-size: 16px;
    text-align: left;
  }
  .reset-password-container h5 {
    font-size: 26px;
  }
  .reset-password-container {
    width: 90%;
    padding: 35px 50px;
  }
  .dont-recive-otp {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 992px) {
  .confirmation-left {
    display: block;
  }
  .responsive-logo {
    display: none;
  }
  .login-confirmation-section {
    background-color: none;
    height: 100vh;
  }
  .reset-password-container {
    width: 90%;
    margin: 0 auto;
    margin-top: 35px;
    padding: 30px 40px;
  }
  .login-confirmation-container {
    width: 95%;
  }
  .confirmation-left img {
    width: 80%;
    margin-bottom: 20px;
  }
  .confirmation-left p {
    width: 380px;
    font-size: 14px;
    text-align: left;
  }
  .lets-get-started p {
    text-align: center;
  }
  .reset-password-container h5 {
    font-size: 26px;
  }
  .reset-password-container p {
    font-size: 15px;
  }
  .reset-password-container span {
    font-size: 11px;
  }
  .reset-password-container button {
    height: 44px;
  }
  .forward-arrow {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 768px) {
  .confirmation-left {
    display: none;
  }
  .login-confirmation-section {
    background: none;
    background-color: #1f8cae;
    background-size: 50% 80%;
    height: 130vh;
  }
  .login-confirmation-container {
    margin-bottom: 50px;
  }
  .reset-password-container {
    width: 90%;
    padding: 40px 80px;
    margin-top: 5px;
  }
  .responsive-logo {
    display: block;
    width: 60%;
    margin: auto;
    margin-top: auto;
    margin-top: 45px;
  }
  .reset-password-container img {
    width: 70px;
    height: 70px;
  }
  .reset-password-container h5 {
    font-size: 24px;
  }
  .reset-password-container p {
    font-size: 16px;
  }
  .reset-password-container button {
    height: 40px;
  }
  .reset-password-container span {
    font-size: 14px;
  }
  .back-arrow {
    margin-top: 3px;
  }
  .forward-arrow {
    font-size: 22px !important;
    margin-top: 10px !important;
  }
}

@media screen and (max-width: 600px) {
  .confirmation-left {
    display: none;
  }
  .login-confirmation-section {
    background: none;
    background-color: #1f8cae;
    height: 120vh;
  }
  .login-confirmation-container {
    margin-bottom: 50px;
  }
  .reset-password-container {
    width: 90%;
    padding: 25px 40px;
    margin-top: 5px;
  }
  .responsive-logo {
    display: block;
    width: 70%;
    margin: auto;
    margin-top: auto;
    margin-top: 25px;
  }
  .reset-password-container img {
    width: 70px;
    height: 70px;
  }
  .reset-password-container h5 {
    font-size: 22px;
  }
  .reset-password-container p {
    font-size: 15px;
  }
  .reset-password-container button {
    height: 40px;
  }
  .reset-password-container span {
    font-size: 12px;
  }
  .back-arrow {
    margin-top: 3px;
  }
}
