.landing-page-banner {
  height: 80vh !important;
}
.nft-market-tabs {
    background-color: #023047 !important;
    color: white !important;
  }
  .nft-market-tabs .Mui-selected {
    background-color: white !important;
    color: black !important;
  }
  .nft-market-tabs button {
    border: 1px solid #fff !important;
    color: #fff !important;
    border-radius: 100px !important;
    margin-left: 30px;
    margin-right: 30px;
    flex: 1 !important;
    text-transform: none !important;
  }
  .nft-market-tabs .MuiTabs-indicator {
    display: none !important;
  }
  .browse-filter-tabs{

  }
.info-faq-container {
  background: #023047;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  overflow: hidden;
}
.ellips-circle1,
.ellips-circle2 {
  position: absolute;
}
.ellips-circle1 {
  top: -34px;
  right: 0px;
  width: 3%;
}
.ellips-circle2 {
  top: 26px;
  right: 0px;
  width: 6%;
}
.info-faq-container > div {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info-faq-container > div > div {
  background: linear-gradient(
    129.59deg,
    rgba(255, 255, 255, 0.1) -0.1%,
    rgba(255, 255, 255, 0.4) 95.18%
  );
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(20px);
  border-radius: 18px;
  display: flex;
  align-items: center;
  padding: 25px 30px;
}
.info-faq-container > div > div:nth-child(1) {
  margin-right: 20px;
}
.info-faq-container > div > div:nth-child(2) {
  margin-left: 20px;
}
.info-faq-container > div > div > img {
  width: 13%;
  margin-right: 15px;
}
.info-faq-container > div > div > h5 {
  color: #fff;
  font-weight: 600;
}
.featured-influencer {
  padding-top: 100px;
  padding-bottom: 80px;
  position: relative;
}
.vector-bg {
  position: absolute;
  left: 0;
  bottom: -5px;
}
.vector-bg img {
  width: 12%;
}
.featured-influencer > div {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.featured-influencer > div > div:nth-child(2) {
  padding-top: 60px;
  padding-bottom: 60px;
  background: #219ebc;
  border-radius: 70px;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.influencer-bg {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0px;
}
.featured-video-gif {
  object-fit: contain;
  background: rgb(0, 0, 0);
  width: 82%;
  border-radius: 20px;
  position: relative;
  z-index: 50;
}
.featured-video-container {
  position: relative;
  z-index: 50;
}
.featured-video-container::before {
  content: "";
  position: absolute;
  border: 18px solid rgba(33, 158, 188, 0.15);
  border-radius: 20px;
  top: -13px;
  left: -13px;
  bottom: 220px;
  right: 241px;
  z-index: 1;
}
.featured-video-container::after {
  content: "";
  position: absolute;
  border: 18px solid rgba(251, 133, 1, 0.16);
  border-radius: 20px;
  top: 106px;
  right: 91px;
  bottom: -13px;
  left: 140px;
  z-index: 1;
}
.featured-influencer h6 {
  color: #023047;
  font-weight: 600;
  text-transform: uppercase;
}
.bttn-styles {
  background: linear-gradient(
    129.59deg,
    rgba(255, 255, 255, 0.4) -0.1%,
    rgba(255, 255, 255, 0.1) 95.18%
  );
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(20px);
  border-radius: 10px;
  background-color: transparent;
}
.join-community-container {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 50px;
}
.join-community-container > div:nth-child(1) {
  background: #ffffff;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 80%;
  margin-right: 20px;
  padding: 70px 50px;
}
.join-community-container > div:nth-child(2) {
  width: 90%;
  margin-left: 20px;
}
.join-community-container img {
  width: 100%;
}
.join-community-container h2 {
  text-align: left;
  color: #219ebc;
  position: relative;
  margin-left: 30px;
}
.join-community-container h2::before,
.featured-influencer-container h2::before {
  content: "";
  position: absolute;
  top: 10px;
  background: #f8991d;
  height: 30px;
  width: 5px;
  left: -20px;
}
.brand-for-impact-container,.featured-campaign {
  width: 90%;
  margin: auto;
  margin-top: 50px;
}
.brand-for-impact-container .brand-video-image {
  width: 100%;
}
.brand-for-impact-container > div, .featured-campaign > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.brand-for-impact-container > div > div:nth-child(1) {
  position: relative;
  z-index: 50;
}
.ellips-circle-bg {
    position: absolute;
    top: -60px;
    left: -20px;
    width: 10%;
}
.brand-for-impact-container .brand-video-image {
  position: relative;
  z-index: 50;
  border-radius: 20px;
}
.brand-for-impact-container > div > div:nth-child(1)::before {
  content: "";
  position: absolute;
  border: 18px solid rgba(33, 158, 188, 0.15);
  border-radius: 20px;
  top: -19px;
  left: 75px;
  bottom: 220px;
  right: 136px;
  z-index: 1;
}
.brand-for-impact-container > div > div:nth-child(1)::after {
  content: "";
  position: absolute;
  border: 18px solid rgba(251, 133, 1, 0.16);
  border-radius: 20px;
  top: 106px;
  right: 60px;
  bottom: -17px;
  left: 160px;
  z-index: 1;
}
.brand-for-impact-container > div > div:nth-child(2) {
  background-image: url("../../assets/marketplace-landing-place/brand-bg.png");
  width: 90%;
  margin-left: 70px;
  padding: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  width: 66%;
  height: 400px;
  
}
.brand-for-impact-container > div > div > h2, .featured-campaign > div > div:nth-child(2) > h2{
  font-weight: 500;
  font-size: 36px;
  color: #219ebc;
  margin: 0;
  margin-bottom: 20px;
  position: relative;
  margin-left: 20px;
  text-align: left;
}
.brand-for-impact-container > div > div > h2::before, .featured-campaign > div > div:nth-child(2) > h2::before{
  content: "";
  position: absolute;
  top: 11px;
  background: #f8991d;
  left: -20px;
  width: 5px;
  height: 30px;
}
.brand-for-impact-container > div > div > h6, .featured-campaign > div > div:nth-child(2) > h6 {
  color: #023047;
  opacity: 0.75;
  line-height: 23px;
  margin-bottom: 20px;
}
.featured-campaign{
    background-image: url("../../assets/marketplace-landing-place/featured-campaign-bg.png");
    background-size: cover;
height: 100vh;
padding-top: 100px;
padding-bottom: 30px;
padding-left: 50px;
padding-right: 50px;
}

.featured-campaign > div > div:nth-child(1)> img{
    width: 100%;
}
.featured-campaign > div > div:nth-child(2){
    background-image: url("../../assets/marketplace-landing-place/campaign-bg.png");
    width: 90%;
    margin-left: 70px;
    padding: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    width: 64%;
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
    border-radius: 40px;
}
.featured-campaign > div > div:nth-child(2) > h2{
    text-transform: capitalize;
}
/* .campaign-showcase-container{
  background-image: url("../../assets/marketplace-landing-place/featured-campaign-bg.png");
  background-size: cover;
height: 102vh;
padding-top: 15px;
padding-left: 50px;
padding-right: 50px;
  width: 90%;
  margin: auto;
  margin-top: 80px;
  overflow: hidden;
} */
.campaign-showcase-container h2{
  margin-bottom: 50px;
}
.campaign-showcase-container{
  background-image: url("../../assets/marketplace-landing-place/campaign-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 95vh;
  width: 90%;
  margin: auto;
  padding-top: 40px;
  background-clip: padding-box;
  border-radius: 20px;
  margin-top: 10px;
  /* position: relative; */
  overflow: hidden;
}
.campaign-showcase-border{
  background-image: url("../../assets/marketplace-landing-place/featured-campaign-bg.png");
  background-repeat: no-repeat;
  width: 90%;
  margin: auto;
  height: 100vh;
  background-size: contain;
  overflow: hidden;
  margin-top: 50px;
}
.single-campaign-showcase{
  width: 90% !important;
  margin: auto;
  background: #fff;
border: 1px solid #E7E8EC;
border-radius: 20px;
padding:20px;
/* margin-right: 40px; */
/* padding-bottom: 40px; */

}
.single-campaign-showcase:first-child{
  margin-left: 15px;
}
.showcase-gallary{
  position: relative;
}
.showcase-gallary-img{
  width: 100%;
  margin: auto;
  border-radius: 10px;
}
.showcase-rating{
  position: absolute;
  top: 20px;
  right: 12px;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 7px;
  font-size: 14px;
}
.showcase-rating svg{
  color: #000000;
  opacity: 0.35;
  font-size: 18px;
  margin-right: 4px;
}
.showcase-rating span{color:#000 !important}
.user-icon1{
  position: absolute;
  bottom: -20px;
  left: 15px;
  width: 35px;
height: 35px;
}
.user-icon2{
  position: absolute;
  bottom: -20px;
  left: 35px;
  width: 35px;
height: 35px;
}
.showcase-heading, .buy-view-history{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.showcase-heading h6{
  font-weight: 600;
  color: #000000;
}
.span-color{
  color: #000000;
  opacity: 0.35;
}
.buy-view-history{
  
}
.buy-view-history h6{
  color:#219EBC;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .landing-page-banner {
    height: 30vh !important;
    background-position: center center;
  }
  .join-community-container{
    display: block;
    width: 100%;
    padding:40px 15px;
  }
  .join-community-container > div:nth-child(1) {
    width: 100%;
    margin-right: 0px;
    padding: 30px;
    margin-bottom: 30px;
  }
  .join-community-container h2{
    font-size: 24px;
  }
  .info-faq-container > div {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .info-faq-container > div > div {
    display: block;
    padding: 15px;
    text-align: center;
  }
  .info-faq-container > div > div > h5 {
    font-size: 14px;
  }
  .info-faq-container > div > div:nth-child(1) {
    margin-right: 5px;
  }
  .info-faq-container > div > div:nth-child(2) {
    margin-left: 5px;
  }
  .info-faq-container > div > div > img {
    width: 30%;
    margin: auto;
    text-align: center;
    margin-bottom: 10px;
  }
  .featured-influencer > div {
    width: 90%;
    display: block;
}
.featured-video-gif {
  width: 100%;
}
.featured-influencer > div > div:nth-child(2){
  margin-top: 40px;
}
/* .campaign-showcase-border{
  width: 95%;
  background-size: cover;
height: auto;
}
.campaign-showcase-container{
  width: 95%;
  background-size: cover;
  height: 100vh;
}
.single-campaign-showcase{
  height: auto;
} */
.brand-for-impact-container > div, .featured-campaign > div{
  display: block;
}
.featured-campaign > div > div:nth-child(1)> img{
  width: 100%;
}
.featured-campaign > div > div:nth-child(2){
  width: 100%;
  margin-left: 0px;
  padding:20px
}
.featured-campaign{
padding-top: 30px;
padding-bottom: 30px;
padding-left: 15px;
padding-right: 15px;
}
.nft-market-tabs button{
  margin-left: 5px;
margin-right: 5px;
}
.nft-market-tabs button {
  font-size: 9px
}
}