@media screen and (max-width: 1300px) {
  .form-wrapper {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
  }
  .input-container {
    position: relative;
    width: 200px;
  }
  .non-profit-section {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .non-profit-container {
    height: auto;
  }
  .form-section {
    width: 90%;
    margin: 0 auto;
  }
  .input-container {
    width: 100%;
  }
  .form-section input,
  .form-section textarea,
  .form-section select {
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .non-profit-section {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .form-wrapper {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  }
  .non-profit-container {
    height: auto;
  }
  .form-section {
    width: 90%;
    margin: 0 auto;
  }
  /* .form-wrapper{
    padding-left: 17%;
  } */
  .input-container {
    width: 100%;
  }
  .form-section input,
  .form-section textarea,
  .form-section select {
    width: 100%;
  }
  .clip-bg {
    width: 0px;
    height: 0px;
  }
  /* .single-input{
    width:0px ;
    margin: auto;
  } */
  .form-section {
    padding-left: 35px;
    padding-right: 35px;
  }
  .btn-div {
    text-align: center;
    margin-top: 10px;
    margin-right: 0px;
  }
  .non-profit-header p {
    width: 400px;
  }
  .non-profit-header h2 {
    font-size: 38px;
  }
  .first {
    order: 1;
  }
  .two {
    order: 2;
  }
  .three {
    order: 3;
  }
  .four {
    order: 4;
  }
  .five {
    order: 5;
  }
  .six {
    order: 6;
  }
  .seven {
    order: 7;
    position: static !important;
    /* margin-top: 10px; */
  }
  .eight {
    order: 8;
    margin-top: 0px !important;
  }
  .nine {
    order: 9;
  }
  .ten {
    order: 10;
  }
  .eleven {
    order: 11;
  }
  .twelve {
    order: 12;
  }
  .thirteen {
    order: 13;
  }
  .fouteen {
    order: 14;
  }
  .fifteen {
    order: 15;
    margin-top: -68px;
  }
  .sixteen {
    order: 16;
    display: none !important;
  }
  .seventen {
    order: 17;
    margin-bottom: 8px !important;
  }
  .eighten {
    order: 18;
  }
  .nineteen {
    order: 19;
  }
  .tweenty {
    order: 20;
    margin-top: 0px !important;
    margin-bottom: 8px !important;
  }
  .tweentyone {
    order: 21;
  }
  .tweentytwo {
    order: 22;
  }
  .tweentythree {
    order: 23;
    margin-top: 0px !important;
  }
  .tweentyfour {
    order: 24;
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 600px) {
  .non-profit-section {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .form-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .non-profit-container {
    height: auto;
  }
  .form-section {
    width: 100%;
    margin: 0 auto;
  }
  /* .form-wrapper{
    padding-left: 17%;
  } */
  .input-container {
    width: 100%;
  }
  .form-section input,
  .form-section textarea,
  .form-section select {
    width: 100%;
  }
  .clip-bg {
    width: 0px;
    height: 0px;
  }
  /* .single-input{
    width:0px ;
    margin: auto;
  } */
  .form-section {
    padding-left: 35px;
    padding-right: 35px;
  }
  .btn-div {
    text-align: center;
    margin-top: 10px;
    margin-right: 0px;
  }
  .submit-btn {
    width: 100%;
  }
  .non-profit-header p {
    width: 250px;
    font-size: 13px;
  }
  .non-profit-header h2 {
    font-size: 32px;
    line-height: 35px;
    margin-bottom: 20px;
  }
  .first {
    order: 1;
  }
  .two {
    order: 2;
  }
  .three {
    order: 3;
  }
  .four {
    order: 4;
  }
  .five {
    order: 5;
  }
  .six {
    order: 6;
  }
  .seven {
    order: 7;
    position: static !important;
    /* margin-top: 10px; */
  }
  .eight {
    order: 8;
    margin-top: 0px !important;
  }
  .nine {
    order: 9;
  }
  .ten {
    order: 10;
  }
  .eleven {
    order: 11;
  }
  .twelve {
    order: 12;
  }
  .thirteen {
    order: 13;
  }
  .fouteen {
    order: 14;
  }
  .fifteen {
    order: 15;
    margin-top: 0 !important;
  }
  .sixteen {
    order: 16;
    display: none !important;
  }
  .seventen {
    order: 17;
    margin-bottom: 8px !important;
  }
  .eighten {
    order: 18;
  }
  .nineteen {
    order: 19;
  }
  .tweenty {
    order: 20;
    margin-top: 0px !important;
    margin-bottom: 8px !important;
  }
  .tweentyone {
    order: 21;
  }
  .tweentytwo {
    order: 22;
  }
  .tweentythree {
    order: 23;
    margin-top: 0px !important;
  }
  .tweentyfour {
    order: 24;
    margin-top: 0px !important;
  }
}
