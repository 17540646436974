.mintingDetails {
  background-color: white;
  border-radius: 10px;
  margin-top: 15px;
  color: black;
  padding: 4em;
  font-size: 14px;
}

.nftMintingInputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  margin-top: 1em;
}
.nftMintingInputs > div {
  display: flex;
  flex-direction: column;
  gap: 0.6em;
}

.uploadFileContainer img {
  width: 80%;
  height: 550px;
  object-fit: contain;
}

.uploadFileContainer {
  grid-column: 2 / span 1;
  grid-row: 1 / span 5;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.uploadFileContainer > span {
  width: 50%;
}

.uploadFileContainer > label > span {
  text-align: center;
  font-weight: bold;
  font-size: 13px !important;
}

.uploadFileContainer > label > span > b {
  color: #fab255;
}

.nftMintingInputs input,
.nftMintingInputs textarea,
.nftMintingInputs select {
  padding: 15px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 13px !important;
}

.nftMintingInputs label {
  font-weight: bold;
  font-size: 13px;
}

.salePriceContainer > div {
  display: flex;
  gap: 1em;
}
.salePriceContainer > div > select {
  flex: 0.3;
}

.salePriceContainer > div > input {
  flex: 1;
}

.nftBtn {
  background-color: #219ebc !important;
  color: whitesmoke;
  padding: 0.5em 2em;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.percentageContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 90%;
  gap: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 12px;
  font-weight: bold;
}

#customPercentage {
  grid-column: 1 / span 4;
}
