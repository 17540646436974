.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerContainer > .headerContentContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 0.5em;
}
.headerContainer > .headerContentContainer > svg {
  width: 25px;
  height: 25px;
}
.headerContainer > .headerContentContainer > hr {
  width: 1px;
  height: 35px;
}
.headerContainer > .headerContentContainer > div {
  display: flex;
  flex-direction: column;
}
.headerContainer > .headerContentContainer > .detailsContainer {
  position: relative;
  font-size: 12px;
}
.headerContainer
  > .headerContentContainer
  > .detailsContainer
  > span:first-child {
  font-size: 14px;
  font-weight: bold;
}
.headerContainer > .headerContentContainer > .walletContainer {
  cursor: pointer;
  color: rgb(128, 128, 128);
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.headerContainer > .headerContentContainer > .walletContainer > div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.headerContainer > .headerContentContainer > .walletContainer > div > span {
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100px;
  height: 1.2em;
  white-space: nowrap;
}

.searchInputContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 0.5em;
  padding: 10px 1rem;
  width: 350px;
  background-color: #fff;
  border-radius: 7px;
}

.searchInputContainer > input {
  border: none;
  outline: none;
  flex: 1;
}

.searchInputContainer > svg {
  fill: #ccc;
  width: 25px;
  height: 25px;
}
