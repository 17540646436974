.loginContainer {
  width: 100%;
  height: 100vh;
  background-color: white;
  display: flex;
}

.loginRight {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loginRight > div {
  width: 65%;
  display: flex;
  flex-direction: column;
}

.loginRight > div > h2 {
  text-align: center;
  font-weight: 700;
}

.loginRight > div {
  background-color: #d9d9d93a;
  width: 85%;
  height: 85%;
  padding: 3em 4em;
  border-radius: 10px;
}

.loginRight > div > h5 {
  margin: 0.7em 0;
}

.loginRight > div > h5 > a {
  color: #fb8501;
  cursor: pointer;
  text-decoration: none;
}

.loginRight > div > form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.loginRight > div > form > span {
  color: #2680eb;
}

.loginRight > div > form > button {
  padding: 0.7em 1.3em;
  background: #fb8501;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  color: white;
  font-weight: 700;
  border-radius: 10px;
  font-size: 1.2em;
  margin-top: 1em;
}

.loginLine {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em 0;
}

.loginLine::before {
  content: 'OR';
  position: absolute;
  background-color: white;
  z-index: 1;
  padding: 0.4em 1em;
  font-weight: 700;
}

.loginLine > hr {
  width: 100%;
}

.loginBtnContainer {
  display: flex;
  gap: 1em;
}
.loginBtnContainer > div {
  display: flex;
  align-items: center;
  border: 1px solid #707070;
  padding: 0.5em 0em 0.5em 0.5em;
  flex: 1;
  cursor: pointer;
}
.loginBtnContainer > div > img {
  width: 25px;
}
.loginBtnContainer > div > button {
  border: none;
  background-color: transparent;
  font-size: 12px;
}

@media screen and (max-width: 1350px) {
  .loginRight > div {
    width: 90%;
  }
}
@media screen and (max-width: 1260px) {
  .loginRight > div {
    padding: 1.5em;
  }
}
@media screen and (max-width: 1080px) {
  .loginLeft {
    width: 50%;
  }
  .loginRight {
    width: 50%;
  }
  .loginLeft > div {
    width: 90%;
  }
  .loginLeft > div > div:first-child > span {
    font-size: 1.3em;
    margin-top: 1em;
  }
  .loginLeft > div > div:last-child > h3 {
    font-size: 1.4em;
  }
  .loginLeft > div > div:last-child > img {
    width: 45px;
  }
  .loginRight {
    align-items: unset;
  }
  .loginRight > div {
    margin: 1.5em 0;
  }
}

@media screen and (max-width: 980px) {
  .loginBtnContainer {
    flex-direction: column;
  }
  .loginLeft > div > div:first-child > span {
    font-size: 1.1em;
    margin-top: 1em;
  }
  .loginLeft > div > div:last-child > h3 {
    font-size: 1.2em;
  }
  .loginLeft > div > div:last-child > img {
    width: 35px;
  }
}

@media screen and (max-width: 768px) {
  .loginLeft {
    display: none;
  }
}
