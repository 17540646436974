.done-for-you-technologies-container{
    width: 90%;
    margin: auto;
}
.technologies-content{

}
.technologies-content h4{
    text-align: left;
    font-size: 1.6rem;
    margin-top: 30px;
}
.technologies-card{
    background: #FFFFFF;
    border-radius: 21px;
    max-width: 15% !important;
    text-align: center;
    height: 80px;
    margin-right: 20px !important;
    margin-bottom: 20px !important;
    box-shadow: 0px 10px 10px 0px #d5d4d4;
}
.technologies-card img{
    width: 70%;
    margin-top: 22px;
}
.javascript-icon img, .wms-icon img{
    width: 23%;
    margin-top: 18px;
}
.rust-icon img, .java-icon img, .ipfs-icon img{
    width: 50%;
margin-top: 13px;
}
.done-for-you-benefits-container{
    width: 90%;
    margin: auto;
    padding-top: 80px;
    padding-bottom: 100px;
}
.benefits-card-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.benefits-card{
    margin-top: 15px;
    position: relative;
    box-shadow: 4px 3px 10px 7px #e2e2e2;
    text-align: center;
    width: 400px;
    height: 400px;
    /* padding: 20px 40px; */
    border-radius: 10px;
}
.innovation-img{
    width: 35%;
}
.cost-img, .chart-img{
    width: 50%;
}

.benefits-card h4{
    margin-top: 15px;
    text-align: left;
    color: #219EBC;
    margin-left: 20px;
    position: relative;
}
.benefits-card h4::before, .approach-container > div:nth-child(2) > div > h4::before {
    content: '';
    position: absolute;
    width: 5px;
    height: 25px;
    background: #f8991d;
    top: 1px;
    left: -20px;
  }
.benefits-card p{
    margin-top: 20px;
    color: #023047;
    opacity: 0.75;
    text-align: left;
    line-height: 22px;
}
.approach-container{
    margin-top: 30px;
    padding-left: 15px;
    padding-right: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.approach-container > div:nth-child(1){
    width: 40%;
}
.approach-container > div:nth-child(1) > h2{
    margin: 0;
}
.approach-container > div:nth-child(1) > img{
    width: 100%;
    margin-top: -30px;
}
.approach-container > div:nth-child(2){
    width: 50%;
}
.approach-container > div:nth-child(2) > div{
    margin-bottom: 40px;
}
.approach-container > div:nth-child(2) > div > h4{
    text-align: left;
    color:#219EBC;
    margin-left: 20px;
    position: relative;
}
.approach-container > div:nth-child(2) > div > h4::before {
    width: 4px;
    height: 22px;
    top: 3px;
  }
.approach-container > div:nth-child(2) > div > p{
    color: #023047;
    opacity: 0.75;
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 6px;
}
.done-for-you-dev-section{
    position: relative;
}
.done-for-you-dev-container{
   width: 90%;
   margin: auto; 
   margin-top: 80px;
}
.development-product{}
.development-product h2{
    margin: 0;
}
.development-product p{
    color: #023047;
    opacity: 0.75;
    text-align: center;
    font-size: 18px;
    width: 60%;
    margin: auto;
    margin-top: 20px;
}
.developer-container{
    margin-top: 80px;
}
.developer-container h2{
    margin-bottom: 40px;
}
.developer-container > div, .developer-container > div > div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.developer-container img{
    margin-right: 10px;
}
.developer-container span{
    color: #023047;
    font-size: 18px;
}
.developer-cards{
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 100px;
}
/* .developer-cards > div{
    background: url(../../assets/done-for-you-partTwo/dev-bg.png);
    width: 80%;
    height: 45vh;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
} */
.developer-cards > div{
    position: relative;
    text-align: center;
}
.developer-cards > div::before{
    content: '';
    position: absolute;
    background: #219EBC;
    border-radius: 43px;
    transform: rotate(10.9deg);
    top: 24px;
    right: 15px;
    z-index: 1;
    width: 70%;
    height: 88%;
}
.developer-cards > div > img{
    width: 80%;
    margin: auto;
    position: relative;
    z-index: 50;
}
.dev-card-content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 50;
}
.dev-card-content img{
    width: 85%;
}
.dev-card-content h5{
    font-weight: 700;
    color: #023047;
}
.developer-plan-section{
    position: relative;
    margin-bottom: 100px;
}
.developer-plan-container{
    width: 80%;
    margin: auto;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
}
.developer-pricing-card{
    background: #FFFFFF;
    box-shadow: 4px 7px 33px 5px rgba(0, 0, 0, 0.25);
    width: 28%;
    height: auto;
    margin: auto;
    border-radius: 25px 25px 0px 0px;
    padding:20px
}
.premium-dev-feature > div{
    display: flex;
}
.developer-border{ border-top: 20px solid #FB8501;}
.developer-pro-border{ border-top: 20px solid #219CBA;}
.developer-premium-border{ border-top: 20px solid #023047;}
.developer-pricing-card> div:nth-child(1){}
.developer-pricing-card> div:nth-child(1) > h4{
    margin-top: 10px;
}
.developer-pricing-card> div:nth-child(1) > h2{
    margin: 0;
    margin-bottom: 5px;
}
.developer-pricing-card> div:nth-child(1) > span{
    display: flex;
    justify-content: center;
    color: #023047;
}
.developer-pricing-card> div:nth-child(2){
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.developer-pricing-card> div:nth-child(2) > div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(129.59deg, rgba(33, 157, 187, 0.53) -0.1%, rgba(33, 156, 186, 0.21) 95.18%);
    box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 5px;
    font-size: 12px;
    padding: 10px;
    margin-top: 15px;
    border:1px solid #219EBC
}
.developer-pricing-card> div:nth-child(3){
    margin-top: 20px;
}
.developer-pricing-card> div:nth-child(3) > div{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.developer-pricing-card> div:nth-child(3) > div > img{
    width: 7%;
    margin-right: 10px;
}
.developer-pricing-card> div:nth-child(3) > div > img > p{}

@media screen and (max-width: 768px) {
    .done-for-you-benefits-container{
        width: 85%;
    }
    .benefits-card-container{
        display: block;
    }
    .benefits-card{
        width: 100%;
        height: 100%;
        margin-bottom: 40px;
    }
    .approach-container{
        display: block;
    }
    .approach-container > div:nth-child(1), .approach-container > div:nth-child(2){
        width: 100%;
    }
    .approach-container{
        width: 80%;
        margin: auto;
        padding-left: 0px;
        padding-right: 0px;
    }
    .approach-container > div:nth-child(1) > h2{
        margin-bottom: 20px;
    }
    .done-for-you-dev-container, .development-product p{
        width: 100%;
    }
    .developer-container > div{
        display: block;
    }
 .developer-container > div > div{
        justify-content:flex-start;
        margin-bottom: 10px;
    }
    .developer-cards{
        display: block;
    }
    .developer-cards > div{
        margin-bottom: 20px;
    }
    .developer-cards > div::before{
        content: '';
        position: absolute;
        background: #219EBC;
        border-radius: 43px;
        transform: rotate(10.9deg);
        top: 24px;
        right: 35px;
        z-index: 1;
        width: 50%;
        height: 88%;
    }
    .developer-plan-container{
        width: 85%;
        display: block;
    }
    .developer-pricing-card{
        width: 100%;
        margin-bottom: 50px;
    }
    .technologies-card{
        max-width: 40% !important;
    }
    .frameworks-technologies-content h4{
        font-size: 18px;
        text-align: left;
        margin-top: 20px;
    }
    .benefits-card> div:nth-child(3){
        padding:40px 40px !important
    }
    .save-costs-card>div:nth-child(3){
        padding:15px 40px !important
    }
    .reduce-risk-card>div:nth-child(3){
        padding:20px 40px !important
    }
}