.short-history-container {
  width: 90%;
  margin: auto;
  padding-top: 100px;
  padding-bottom: 20px;
}
.short-history-container h2 {
  text-transform: capitalize;
  font-weight: 700;
  color: #219ebc;
  text-align: left;
  margin: 0;
  margin-bottom: 15px;
}
.short-history-container h4 {
  font-size: 22px !important;
  margin-bottom: 20px;
}
.industrial-revolution {
  margin-top: 50px;
  padding: 20px 30px;
  background: #ffffff;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
}
.the-evolution {
  margin-top: 50px;
  padding: 20px 30px;
  background: #f3f5f9;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
}
.sub-content {
  margin-left: 20px;
}
.sub-content h4 {
  position: relative;
}
.sub-content h4::before {
  top: 5px;
}
.the-evolution svg{
  color: #F8991D;
  font-size: 7px;
  margin-right: 10px;
}

