.services-container {
  width: 90%;
  margin: auto;
  overflow: hidden;
  padding-top: 100px;
}
.services-container h4 {
  font-weight: 700;
  font-size: 70px;
  line-height: 140%;
  text-align: center;
  color: #023047;
}
.services-container p {
  width: 76%;
  margin: auto;
  font-weight: 500;
  font-size: 42px;
  line-height: 140%;
  text-align: center;
  color: #023047;
  padding-bottom: 40px;
}
.services-list-container {
  width: 90%;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 70px;
}
.service-list-header {
  background: #8ecae6;
  border: 1px solid #023047;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  position: relative;
  padding: 20px 0;
}
.service-list-header h5 {
  font-weight: 700;
  font-size: 25px;
  line-height: 140%;
  text-align: center;
  color: #023047;
}
.arrow-icon {
  position: absolute;
  color: #fff;
  background: #fb8501;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  left: 50%;
  transform: translateX(-50%);
  bottom: -20px;
}

.single-list {
  background: rgba(252, 232, 135, 0.74);
  border: 0.5px solid #023047;
  border-radius: 10px;
  position: relative;
}
.check-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  left: 10px;
}
.single-list p {
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: #023047;
  position: relative;
  top: 8px;
  text-align: left;
  margin-left: 80px;
}
.tech-stack-knowledge-container {
}
.tech-stack-knowledge-container h4 {
  font-weight: 700;
  font-size: 55px;
  line-height: 140%;
  text-align: center;
  color: #023047;
  margin-top: 120px;
}
.tech-stack-knowledge-container p {
  font-weight: 500;
  font-size: 40px;
  line-height: 140%;
  text-align: center;
  color: #023047;
  width: 1190px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.project-container {
  width: 100%;
  padding-left: 80px;
  padding-right: 80px;
}
.single-project {
  text-align: center;
  margin-bottom: 50px;
  box-shadow: 20px 14px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.single-project img {
  width: 100%;
  border-radius: 10px;
  height: 300px;
}
.single-project h4 {
  font-weight: 600;
  font-size: 28px;
  line-height: 140%;
  text-align: center;
  color: #023047;
  margin-bottom: 5px;
  margin-top: 35px;
}
.single-project a {
  font-weight: 400;
  font-size: 21px;
  line-height: 140%;
  text-align: center;
  color: #479cc7;
  text-decoration: none;
  text-align: center;
}
.project-content {
  height: 145px;
  padding-left: 15px;
  padding-right: 15px;
}
.project-content p {
}
.col-padding {
  padding-left: 20px;
  padding-right: 20px;
}
.project-container h2 {
  font-weight: 700;
  font-size: 48px;
  line-height: 140%;
  text-align: center;
  color: #023047;
  margin-bottom: 40px;
}
.list-header {
  margin-top: 20px;
  margin-bottom: 50px;
}
.reachout-btn,
.services-btn {
  background-color: #fb8501;
  color: #fff;
  padding: 2px 20px;
  border: none;
  font-weight: 500;
  margin-bottom: 30px;
  font-size: 25px;
  margin-top: 10px;
  box-shadow: 0px 3px 10px 0px #b3b2b2;
  border-radius: 4px;
}

/*Responsive CSS */
@media screen and (max-width: 1300px) {
  .project-container {
    padding-left: 30px;
    padding-right: 30px;
  }
  .single-project h4 {
    font-weight: 600;
    font-size: 20px;
  }
  .single-project a {
    font-weight: 400;
    font-size: 17px;
    display: inline-block;
  }
  .single-project img {
    height: 200px;
  }
  .single-project img {
    max-width: 100% !important;
  }
  .services-container p {
    font-size: 26px;
    margin-top: 20px;
  }
  .services-container h4 {
    font-size: 40px;
  }
  .resposive-btn {
    font-size: 20px;
  }
  .tech-stack-knowledge-container h4 {
    font-size: 36px;
  }
  .tech-stack-knowledge-container p {
    font-size: 24px;
    width: 90%;
  }
  .project-container h2 {
    font-size: 34px;
  }

  .service-list-header h5 {
    font-size: 26px;
  }
  .single-list p {
    font-size: 16px;
    top: 9px;
  }
  .check-icon {
    font-size: 14px;
  }
  .arrow-icon {
    width: 40px;
    height: 40px;
    bottom: -15px;
  }
  .list-header {
    margin-top: 80px;
    margin-bottom: 50px;
  }
  .resposive-btn {
    padding: 10px 30px !important;
  }
}

@media screen and (max-width: 992px) {
  .project-container {
    padding-left: 30px;
    padding-right: 30px;
  }
  .single-project h4 {
    font-weight: 600;
    font-size: 24px;
  }
  .single-project a {
    font-weight: 400;
    font-size: 22px;
    text-align: center;
  }
  .single-project img {
    max-width: 100% !important;
  }
  .services-container p {
    font-size: 24px;
    margin-top: 20px;
  }
  .services-container h4 {
    font-size: 38px;
  }
  .resposive-btn {
    font-size: 18px;
  }
  .tech-stack-knowledge-container h4 {
    font-size: 34px;
  }
  .tech-stack-knowledge-container p {
    font-size: 22px;
    width: 90%;
  }
  .project-container h2 {
    font-size: 32px;
  }
  .service-list-header h5 {
    font-size: 23px;
  }
  .single-list p {
    font-size: 14px;
    top: 9px;
  }
  .check-icon {
    font-size: 26px;
  }
  .arrow-icon {
    width: 40px;
    height: 40px;
    bottom: -15px;
  }
  .list-header {
    margin-bottom: 50px;
  }
  .resposive-btn {
    padding: 10px 30px !important;
  }
}

@media screen and (max-width: 600px) {
  .project-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .single-project a {
    font-weight: 400;
    font-size: 17px;
  }
  .project-container {
    width: 90%;
    margin: 0 auto;
  }
  .single-project img {
    max-width: 100% !important;
  }
  .services-container h4 {
    font-size: 40px;
    line-height: 50px;
  }
  .services-container p {
    font-size: 22px;
    margin-top: 20px;
  }
  .resposive-btn {
    font-size: 16px;
  }
  .tech-stack-knowledge-container h4 {
    font-size: 32px;
  }
  .tech-stack-knowledge-container p {
    font-size: 20px;
    width: 90%;
  }
  .project-container h2 {
    font-size: 34px;
  }
  .service-list-header h5 {
    font-size: 20px;
  }
  .single-list p {
    font-size: 14px;
    top: 9px;
  }
  .check-icon {
    font-size: 26px;
  }
  .arrow-icon {
    width: 30px;
    height: 30px;
    bottom: -15px;
  }
  .list-header {
    margin-bottom: 40px;
  }
  .resposive-btn {
    padding: 8px 30px !important;
  }
}
