.new-password-container {
  height: 100vh;
}
.password-reset-form {
  text-align: left;
}

.password-reset-form > input {
  width: 100%;
}
.password-input-field {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 25px;
  gap: 0.5em;
}
.password-input-field-margin {
  margin-bottom: 5px;
}

.forgot-password a {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #2680eb;
  text-decoration: none;
  display: inline-block;
  margin-top: -10px;
}
