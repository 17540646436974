.reactSelect {
  min-width: 300px;
}
.browseSearch {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr auto;
  gap: 1em;
  align-items: end;
  justify-content: center;
}
.browseSearch > div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.browse-category-section {
  width: 80%;
  margin: auto;
  padding-top: 80px;
  padding-bottom: 200px;
}
.browse-category-section button {
  padding: 15px 20px !important;
  text-align: center;
}
.browse-search-icon {
  margin-top: -2px;
  margin-right: 10px;
}
.browse-cards {
  margin-top: 100px;
}
.browse-cards img {
  width: 100%;
  background: #f5f5f5;
  border-radius: 10px;
  padding: 22px 10px;
}
.browse-cards h4 {
  background: #219ebc;
  text-align: left;
  padding: 10px;
  color: #fff;
  text-transform: capitalize;
  margin: 0;
}
.browse-card-container {
  box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  cursor: pointer;
}
.browse-card-content {
  padding: 12px 10px;
}
.browse-card-content span {
  color: #219ebc;
  opacity: 0.75;
  font-size: 12px;
}
.browse-card-content p {
  color: #023047;
  opacity: 0.75;
  font-size: 14px;
  margin-top: 5px;
}
.browse-sub-category {
  position: relative;
}
.browse-sub-category h4 {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #f8991d;
  box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 14px 0px 0px 0px;
  padding: 8px 25px;
}

@media screen and (max-width: 992px) {
  .browse-category-section button {
    padding: 10px 15px !important;
    font-size: 14px !important;
    display: block !important;
    margin-bottom: 20px;
    margin-left: 0px;
    margin-right: 0px;
    text-align: center;
    width: 100%;
  }
  .reactSelect {
    min-width: 200px;
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .reactSelect {
    min-width: 100%;
  }

  .browseSearch {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 425px) {
  .browseSearch {
    flex-direction: column;
  }
  .reactSelect {
    min-width: 100%;
  }
}
