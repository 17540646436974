.here-to-help {
  width: 60%;
  margin: auto;
  background: #ffffff;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 30px 50px;
  margin-bottom: 50px;
}
.here-to-help h2 {
  margin: 0;
  margin-bottom: 20px;
}
.here-to-help p {
  color: #023047;
  opacity: 0.75;
  text-align: center;
  font-size: 18px;
}
.impact-solution-content {
  background: #ffffff;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 50px 30px;
}
.impact-solution-content h2 {
  text-align: left;
  margin: 0;
  margin-bottom: 20px;
  position: relative;
  margin-left: 20px;
}
.impact-solution-content h2::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 30px;
  background: #f8991d;
  top: 8px;
  left: -20px;
}
.impact-solution-content p {
  color: #023047;
  opacity: 0.75;
}
.book-exploratory-call-container {
  margin-top: 80px;
  margin-bottom: 50px;
  background: rgba(217, 217, 217, 0.22);
  padding: 50px 20px;
}
.booking-card-container {
  max-width: 15% !important;
  height: 160px;
  margin: auto !important;
  margin-bottom: 20px !important;
  background: #ffffff;
  box-shadow: 0px 8px 16px 7px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  padding: 15px;
  /* padding-bottom: 15px; */
}
.book-exploratory-call-container img {
  width: 20%;
}
.book-exploratory-call-container h5 {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
.advisor-section {
  margin-bottom: 80px;
}
.advisor-card {
  margin-bottom: 50px !important;
}
.advisor-card img {
  width: 100%;
  border-radius: 20px;
}
.advisor-img {
  position: relative;
  overflow: hidden;
}
.advisor-img-bg {
  position: absolute;
  top: -15px;
  left: -16px;
  width: 68% !important;
}
.advisor-name {
}
.advisor-name h5 {
  font-weight: 700;
  font-size: 16px;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 3px;
  text-transform: uppercase;
}
.advisor-name span {
  color: #219ebc;
  font-weight: 700;
  font-size: 12px;
}
@media screen and (max-width: 992px) {
  .booking-card-container {
    max-width: 30% !important;
  }
}
@media screen and (max-width: 768px) {
  .here-to-help {
    width: 80%;
    padding: 20px 30px;
  }
  .here-to-help p {
    font-size: 16px;
  }
  .impact-solutions img {
    max-width: 100%;
  }
  .advisor-img-bg {
    top: -15px;
    left: -19px;
    width: 60% !important;
  }
  .advisor-name {
    text-align: center;
  }
  .booking-card-container {
    max-width: 45% !important;
  }
}
