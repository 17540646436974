.footerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #219ebc;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #023047;
  padding: 2em 0em;
}

.footer-logo {
  text-align: center;
  border-top: 1px solid rgba(2, 48, 71, 0.5);
}
.footer-logo img {
  width: 30%;
  padding-top: 20px;
}
.footer-quote {
  text-align: center;
}
.footer-quote img {
  width: 50%;
  padding-top: 30px;
  padding-bottom: 25px;
}
.footer-quote {
  border-bottom: 1px solid rgba(2, 48, 71, 0.5);
}
.footerLinks {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  height: 65%;
  font-weight: 600;
  color: #023047;
  font-size: 14px;
  margin-right: 3em;
}
.footerHeader {
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 1rem;
  height: 30px;
  color: #fff;
}
.footerLinks > div {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1em;
  justify-content: space-around;
}
.footerLinks > div > a {
  color: #023047;
  text-decoration: none;
  text-transform: capitalize;
  cursor: pointer;
  user-select: none;
}
.info-hub-links a {
  text-transform: uppercase !important;
}

.footerForm {
  gap: 0 !important;
}
.footerForm > input {
  padding: 5px 10px;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 0.5em;
}
.footer-copyright {
  text-align: center;
}
.footer-copyright p {
  font-weight: 600;
  line-height: 30px;
  color: #023047;
  font-size: 18px;
  padding-top: 20px;
  padding-bottom: 15px;
}

@media screen and (max-width: 1200px) {
  .footerLinks {
    font-size: 1em;
  }
}
@media screen and (max-width: 900px) {
  .footerLinks {
    font-size: 0.9em;
  }
}

@media screen and (max-width: 768px) {
  .footerLinks {
    font-size: 0.8em;
  }
  .footerHeader {
    font-size: 1.2em;
  }
  #footerBtn {
    font-size: 0.8em;
    width: fit-content;
  }
  .footer-quote img {
    width: 90%;
  }
  .footer-logo img {
    width: 50%;
  }
}

@media screen and (max-width: 540px) {
  .footerLinks {
    font-size: 0.7em;
  }
  .footerHeader {
    font-size: 1em;
    height: 15px;
  }
  #footerBtn {
    font-size: 0.8em;
    width: fit-content;
    padding: 5px 10px;
  }
  .footer-quote img {
    width: 90%;
  }
  .footer-logo img {
    width: 50%;
  }
  .footer-copyright p {
    font-size: 16px;
  }
}

@media screen and (max-width: 425px) {
  .footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .footer > div:first-child {
    grid-column: 1 / span 2;
    grid-row: 2;
  }
  .footer-quote img {
    width: 90%;
  }
  .footer-logo img {
    width: 50%;
  }
  .footer-copyright p {
    font-size: 16px;
  }
}
