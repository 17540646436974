.howDoWeDoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 2em;
}
.howDoWeDoCardsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1em;
  width: 100%;
  margin-bottom: 1em;
}
.howDoWeDoCardsContainer > div {
  display: flex;
  justify-content: space-between;
}
.howDoWeDoCardsContainer > div {
  display: flex;
  justify-content: space-between;
}

.howDoWeDoCardsContainer > div > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.howDoWeDoCardsContainer > div:first-child > div {
  flex: 1;
}
.howDoWeDoCardsContainer > div:last-child {
  display: none;
  justify-content: space-evenly;
}
.howDoWeDoCardsContainer > div > div > img {
  width: 150px;
  padding: 1em;
  border: 1px dashed #023047;
  border-radius: 100%;
  margin-bottom: 1em;
}

.howDoWeDoCardsContainer > div span {
  font-weight: 500;
  font-size: 1.5em;
  text-align: center;
}

.howDoWeDoBtnContainer {
  margin: 1.5em 0px;
  display: flex;
  gap: 1em;
  align-items: center;
  width: 450px;
  position: relative;
  z-index: 10;
}

.howDoWeDoBtnContainer > button {
  background-color: #fb8501;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: white;
  font-weight: 700;
  padding: 1em 2em;
}

.howDoWeDoBtnContainer > div {
  display: flex;
  flex-direction: column;
}
.howDoWeDoBtnContainer > div > img {
  width: 120px;
}

@media screen and (max-width: 1150px) {
  .howDoWeDoCardsContainer > div > div > img {
    width: 120px;
    padding: 1em;
  }

  .howDoWeDoCardsContainer > div > div > span {
    font-size: 1.1em;
  }
}
@media screen and (max-width: 900px) {
  .howDoWeDoCardsContainer > div > div > img {
    width: 110px;
    padding: 0.8em;
  }

  .howDoWeDoCardsContainer > div > div > span {
    font-size: 1em;
  }
}
@media screen and (max-width: 768px) {
  .howDoWeDoCardsContainer > div > div > img {
    width: 100px;
    padding: 0.8em;
  }

  .howDoWeDoCardsContainer > div > div > span {
    font-size: 0.9em;
  }

  .howDoWeDoCardsContainer > div:first-child > div:nth-child(even) {
    display: none;
  }
  .howDoWeDoCardsContainer > div:last-child {
    display: flex;
  }
  .howDoWeDoCardsContainer > div:last-child > div {
    flex: 1;
  }

  .howDoWeDoBtnContainer {
    margin: 1em 0px;
    width: 100%;
  }

  .howDoWeDoBtnContainer > button {
    padding: 0.7em 1.5em;
  }
}
@media screen and (max-width: 500px) {
  .howDoWeDoCardsContainer > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
  }
  .howDoWeDoCardsContainer > div > div:first-child {
    grid-column: 1 / span 2;
  }
  .howDoWeDoCardsContainer > div > div > img {
    width: 100px;
    padding: 0.8em;
  }

  .howDoWeDoCardsContainer > div > div > span {
    font-size: 0.8em;
  }

  .howDoWeDoCardsContainer > div:first-child > div:nth-child(even) {
    display: flex;
  }
  .howDoWeDoCardsContainer > div:last-child {
    display: none;
  }

  .howDoWeDoBtnContainer {
    margin: 1em 0px;
    width: 100%;
  }

  .howDoWeDoBtnContainer > button {
    padding: 0.7em 1.5em;
    font-size: 0.9em;
  }

  .howDoWeDoBtnContainer > div > img {
    width: 100px;
  }
}

@media screen and (max-width: 425px) {
  .howDoWeDoBtnContainer > div > img {
    width: 90px;
  }
  .howDoWeDoBtnContainer > div > span {
    font-size: 0.8em;
  }
  .howDoWeDoBtnContainer > button {
    padding: 0.5em 1em;
    font-size: 0.8em;
  }
}

@media screen and (max-width: 375px) {
  .howDoWeDoCardsContainer > div > div > img {
    width: 80px;
    padding: 0.8em;
  }

  .howDoWeDoCardsContainer > div > div > span {
    font-size: 0.7em;
  }
}
