.services-intro {
  margin-top: 80px;
  margin-bottom: 80px;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.services-intro div:nth-child(2) {
  background: #ffffff;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 60px 50px;
  width: 80%;
}
.services-intro img {
  width: 90%;
}
.services-intro h5 {
  font-weight: 700;
  line-height: 35px;
  text-align: center;
  color: #219ebc;
}
.service-container {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.service-container > div {
  width: 90%;
}
.service-container > div:nth-child(1) {
  margin-right: 20px;
}
.service-container > div:nth-child(2) {
  margin-left: 20px;
}
.service-container h4 {
  background: #f8991d;
  border-radius: 18px;
  color: #ffffff;
  margin: 0;
  padding: 20px;
}
.service-container ul {
  padding: 0px;
  background: #f4f6f9;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 18px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.service-container ul li {
  list-style: none;
  font-size: 18px;
  line-height: 131%;
  color: #023047;
  opacity: 0.75;
  padding: 22px;
  border-bottom: 1px solid #d9d9d9;
}
.service-container ul li:last-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.service-container ul li svg {
  color: #f8991d;
  background: #000;
  border-radius: 50%;
  padding: 5px;
  font-size: 25px;
  margin-right: 15px;
}
.service-btn {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 80px;
}
.up-arrow {
  background: none !important;
  font-size: 40px !important;
}

@media screen and (max-width: 768px) {
  .services-intro {
    margin-top: 30px;
    margin-bottom: 30px;
    display: block;
  }
  .services-intro div:nth-child(2) {
    padding: 25px 15px;
    width: 100%;
  }
  .services-intro h5 {
    line-height: 25px;
    font-size: 16px;
  }
  .service-container {
    display: block;
    margin-top: 50px;
  }
  .service-container > div {
    width: 100%;
  }
  .service-container > div:nth-child(1) {
    margin-right: 0px;
    margin-bottom: 50px;
  }
  .service-container > div:nth-child(2) {
    margin-left: 0px;
  }
  .service-container ul li {
    font-size: 14px;
    padding: 15px;
  }
  .service-container ul li svg {
    padding: 3px;
    font-size: 20px;
  }
}
