.dashboard-header-section {
  background: #023047;
}
.dashboard-header-section > div {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard-logo img {
  width: 50%;
}
.user-logo img {
  width: 35%;
}
.user-logo {
  background: #284f62;
  padding: 20px;
}
.user-logo span {
  color: #fff;
  font-weight: 600;
  margin-left: 10px;
}
.dashboard-banner-section {
  width: 100%;
  margin: auto;
}
.dashboard-banner-section button:hover {
  background: transparent;
}
.dashboard-banner-section img {
  width: 100%;
}
.banner-container {
  position: relative;
}
.banner-container::before {
  content: '';
  position: absolute;
  background: rgba(248, 153, 29, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.change-cover {
  position: absolute;
  right: 15px;
  top: 10px;
  background: #f4f6f9;
  border-radius: 6px;
  padding: 8px 13px;
  font-size: 14px;
}
.change-cover label,
.change-profile label {
  cursor: pointer;
}
.user-personal-details-container {
  width: 100%;
  margin: auto;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: -50px;
  position: relative;
  z-index: 100;
  margin-bottom: 80px;
}
.user-personal-details {
  width: 80%;
  background: #ffffff;
  box-shadow: 7px 8px 23px 4px rgba(0, 0, 0, 0.15);
}
.user-profile-sidebar {
  width: 20%;
  padding-right: 20px;
}
.profile-picture {
  position: relative;
}
.change-profile {
  position: absolute;
  top: 20%;
  left: 67%;
  transform: translate(-50%, -50%);
}
.change-profile svg {
  background: #219ebc;
  color: #fff;
  padding: 6px;
  font-size: 28px;
  border-radius: 50%;
}
.user-profile-sidebar > div:nth-child(1) {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 20px;
}
.user-profile-sidebar > div:nth-child(1) img {
  width: 40%;
}
.user-profile-sidebar > div:nth-child(1) h6 {
  font-weight: 600;
  margin-top: 10px;
}
.user-profile-sidebar > div:nth-child(1),
.user-social-links {
  background: #ffffff;
  box-shadow: 0px 4px 13px 3px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
}
.user-social-links {
  padding: 15px 20px;
}
.user-social-links div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.user-social-links h6 {
  font-weight: 600;
}
.user-social-links button {
  font-size: 12px;

  background: #f8991d;
  border-radius: 7px;
  border: none;
  color: #fff;
  font-weight: 600;
  padding: 4px 15px;
}
.user-social-links button svg {
  font-size: 16px;
}

.user-social-links input {
  margin-left: 15px;
  padding: 4px;
  border: 1px solid #aeaeae;
  border-radius: 4px;
}
input:focus {
  outline: none;
}
.user-social-links svg {
  font-size: 24px;
}
.user-personal-details {
  padding: 30px 20px;
}
.user-details-form h6 {
  font-weight: 600;
  position: relative;
  margin: 0;
  margin-left: 10px;
}
.user-details-form h6::before {
  content: '';
  position: absolute;
  top: 0px;
  background: #f8991d;
  left: -10px;
  width: 4px;
  height: 20px;
}

@media screen and (max-width: 768px) {
  .user-logo {
    padding: 10px;
    width: 58%;
  }
  .user-logo span {
    font-size: 10px;
  }
  .dashboard-banner-section {
    width: 100%;
  }
  .user-personal-details-container {
    width: 100%;
    margin-top: -20px;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
  }
  .user-personal-details {
    width: 100%;
  }
  .user-profile-sidebar {
    width: 100%;
    padding-right: 0px;
  }
  .user-social-links {
    margin-bottom: 30px;
  }
  .dashboard-banner-section img {
    height: 150px;
  }
}
