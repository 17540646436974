.nftCampaignContainer {
  display: flex;
  align-items: center;
  flex-direction: row-reverse !important;
  justify-content: space-between;
  padding-bottom: 40px;
}
.nftCompaignLeft {
  position: relative;
  align-self: end !important;
  background: #ffffff;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 100%;
  padding: 30px;
  overflow: hidden;
}
.nftCompaignLeft::before {
  content: "";
  position: absolute;
  border: 1px solid #f8991d;
  right: 3%;
  top: -2%;
  left: -2%;
  bottom: 5%;
}
.nftCompaignLeft > div {
  display: flex;
  flex-direction: column;
  gap: 1em;
  flex: 1;
}
.nftCompaignLeft > div > div {
  display: flex;
  align-items: center;
  gap: 1em;
  font-weight: bold;
  font-size: 16px;
}
.nftCompaignLeft h2 {
  position: relative;
  margin-left: 20px !important;
}
.nftCompaignLeft h2::before {
  content: "";
  position: absolute;
  height: 28px;
  width: 5px;
  background: #f8991d;
  top: 10px;
  left: -18px;
}
.nftCompaignLeft > div > div > img {
  width: 70px;
}
.nftCompaignLeft > div > div > span {
  font-size: 1.5em;
}
.nftCompaignLeft > div > span {
  font-size: 18px;
  width: 100%;
  text-align: justify;
}

.nftCompaignRight {
  display: flex;
  gap: 1em;
}
.mainImage {
  height: 400px !important;
  object-fit: contain !important;
}
.nftCompaignRight > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.nftCompaignRight > div img {
  height: 115px;
  object-fit: cover;
}
.nftCompaignRight > div > div {
  position: relative;
}
.nftCompaignRight > div > div::after {
  content: " 3+";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  font-weight: 700;
  display: flex;
  align-items: center;
  font-size: 1.5em;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.724);
}
.setup-campaign {
  position: relative;
  background: #023047;
  padding-top: 60px;
  padding-bottom: 60px;
  overflow: hidden;
  margin-bottom: 50px;
}
.setup-campaign-container {
  /* width: 100%; */
  /* margin: auto; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.campaign-vector1 {
  position: absolute;
  top: 0;
  width: 14%;
  left: 20px;
}
.campaign-vector2 {
  position: absolute;
  bottom: 12%;
  width: 20%;
  left: 0;
}
.setup-campaign h4 {
  color: #f8991d !important;
  text-transform: capitalize;
  font-size: 30px;
  width: 100%;
  text-align: left;
  line-height: 40px;
  letter-spacing: 0.2px;
}

@media screen and (max-width: 1380px) {
  .nftCompaignLeft > div > span {
    width: 100%;
  }
}
@media screen and (max-width: 1080px) {
  .nftCompaignLeft > h2 {
    font-size: 1.7em;
  }
  .nftCompaignLeft > div > h3 {
    font-size: 1.5em;
  }
  .nftCompaignLeft > div > div > img {
    width: 80px;
  }
  .nftCompaignLeft > div > div > span {
    font-size: 1.1em;
  }
  .nftCompaignLeft > div > span {
    font-size: 1em;
    width: 90%;
  }
  #learnMoreBtn {
    padding: 7px 14px;
    margin: 0.7em 0;
  }
}
.featured-image{
  position: relative;
}
.inner-logo{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 930px) {
  .leftImageSide {
    width: 100px;
    display: none !important;
  }
}

@media screen and (max-width: 870px) {
  .nftCompaignRight {
    gap: 0.5em;
  }
  .nftCompaignRight > img {
    width: 270px;
  }
  .nftCompaignRight > div {
    gap: 0.5em;
  }
  .nftCompaignRight > div img {
    /* flex: 1; */
    margin-bottom: 50px;
    margin-top: 20px;
  }
}

@media screen and (max-width: 768px) {
  .nftCompaignLeft > h2 {
    font-size: 1.2em;
  }
  .nftCompaignLeft > div > h3 {
    font-size: 1em;
  }
  .nftCompaignLeft > div > div > img {
    width: 50px;
  }
  .nftCompaignLeft > div > div > span {
    font-size: 0.9em;
  }
  .nftCompaignLeft > div > span {
    font-size: 0.9em;
    width: 90%;
  }
  #learnMoreBtn {
    font-size: 0.9em;
  }
  .nftCompaignRight > img {
    width: 250px;
    height: 270px;
  }
}

@media screen and (max-width: 600px) {
  .nftCampaignContainer {
    flex-direction: column-reverse !important;
  }
  .setup-campaign-container{
    display: block;
    text-align: center;
  }
  .setup-campaign h4 {
    font-size: 24px;
    text-align: center;
    line-height: 30px;
  }
  .campaign-vector1,
  .campaign-vector2 {
    display: none;
  }
}
