.infulencer-page-container h2 {
  font-weight: 700;
  font-size: 50px;
  line-height: 58px;
  text-align: center;
  color: #219ebc;
}
.influencer-banner-section {
  background-image: url('../../assets/nft-details/nft-details-banner.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.brand-domination-section {
  display: flex;
  height: 50vh;
  align-items: center;
  justify-content: center;
}

.brand-domination-section > h2 {
  margin-bottom: 15vh;
}

.opportunities-content {
  padding: 0px 20px;
  text-align: center;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.opportunities-content p {
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  color: #f4f6f9;
  width: 60%;
  padding-bottom: 50px;
}
.opportunities-content img {
  width: 70%;
  height: 500px;
  margin-top: -225px;
  margin-bottom: 40px;
  object-fit: contain;
}

.demo-section {
  background: #ffffff;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 80%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}
.impactoverce-border {
  position: absolute;
  top: -4%;
  border: 1px solid #fb8501;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  left: -3%;
}

.demo-content {
  padding: 40px 0;
}

.demo-content h2 {
  padding-bottom: 15px;
}

.demo-content p {
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: #023047;
  width: 90%;
  margin: auto;
  margin-bottom: 20px;
}
.HowWeWork-section {
  width: 90%;
  margin: auto;
  padding: 160px 0;
}
.work-img {
  position: relative;
}
.work-main-img {
  width: 100%;
  z-index: 50;
  position: relative;
}
.workElips {
  position: absolute;
  top: 580px;
  width: 10% !important;
  left: -10px;
  z-index: 30;
}
.workElips2 {
  position: absolute;
  top: 600px;
  width: 7% !important;
  left: 15px;
}
.how-we-work-content {
  position: relative;
}
.contentElips {
  position: absolute;
  top: -20%;
  width: 25%;
  right: 0;
}
.contentElips2 {
  position: absolute;
  top: 100%;
  width: 25%;
  right: 0;
}
.how-we-work-content h4 {
  font-weight: 700;
  font-size: 34px;
  line-height: 70px;
  color: #219ebc;
}
.single-work-content {
}
.single-work-content h4 {
  font-weight: 600;
  font-size: 28px;
  line-height: 54px;
  color: #023047;
  position: relative;
  padding-left: 15px;
}
.single-work-content h4::before {
  content: '';
  position: absolute;
  width: 3px;
  height: 18px;
  background: #f8991d;
  top: 18px;
  left: 0px;
}
.work-align {
  align-items: center;
}
.single-work-content p {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #219ebc;
  width: 80%;
}

.benefits-influence-content ul {
  width: 85%;
  margin: auto;
  display: flex;
  height: 80%;
  justify-content: space-evenly;
  flex-direction: column;
}

.benefits-influence-content ul li {
  list-style: none;
  font-weight: 400;
  font-size: 20px;
  color: #219ebc;
  margin-top: 15px;
}

.benefits-influence-content {
  background: #ffffff;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 90%;
  height: 80%;
  margin: auto;
  padding-top: 50px;
  padding-bottom: 30px;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.learning-more-section {
  padding-top: 50px;
  padding-bottom: 120px;
}

.learning-more-section h2 {
  line-height: 85px;
  margin: 0;
}

.learning-more-section p {
  font-weight: 400;
  font-size: 30px;
  text-align: center;
  color: #219ebc;
  width: 48%;
  margin: auto;
  line-height: 35px;
  padding-bottom: 80px;
}
.video-playing {
  position: relative;
  width: 60%;
  margin: auto;
}
.video-playing img {
  width: 100%;
}
.video-playing::before {
  content: '';
  position: absolute;
  background: linear-gradient(
    180deg,
    #023047 0%,
    rgba(2, 48, 71, 0.19) 81.77%,
    rgba(2, 48, 71, 0) 86.46%
  );
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 28px;
}
.video-playing h4 {
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  position: absolute;
  left: 22%;
  bottom: 22%;
}

/* Responsive css */
@media screen and (max-width: 992px) {
  .brand-domination-section h2 {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 23px;
    line-height: 34px;
    padding-top: 40px;
    padding-bottom: 120px;
  }
  .opportunities-section img {
    margin-top: -150px;
  }
  .opportunities-content {
    padding: 0px 15px;
  }
  .opportunities-section p {
    font-size: 22px;
    line-height: 32px;
    margin-top: 40px;
  }
  .infulencer-page-container h2 {
    font-size: 35px;
    line-height: 45px;
  }
  .demo-content p {
    font-size: 16px;
  }
  .workElips,
  .workElips2,
  .contentElips,
  .contentElips2 {
    display: none;
  }
  .benefits-influence-section ul li {
    font-size: 18px;
    line-height: 32px;
  }
  .learning-more-section h2 {
    width: 80%;
    margin: auto;
    margin-bottom: auto;
    margin-bottom: 20px;
  }
  .learning-more-section p {
    font-size: 20px;
    width: 100%;
    line-height: 28px;
  }
  .learning-more-section {
    padding-left: 10px;
    padding-right: 10px;
  }
  .influencer-banner-section h1 {
    font-size: 46px;
    line-height: 55px;
  }
  .influencer-banner-section {
    padding: 100px;
  }
  .video-playing {
    width: 80%;
  }
  .video-playing h4 {
    font-size: 16px;
  }
  .video-playing img {
    padding: 0;
  }
  .video-playing::before {
    border-radius: 13px;
  }
}
