.enter-otp {
  display: flex;
  text-align: left;
  flex-direction: column;
}
.enter-otp p {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #231f1f;
  margin-bottom: 10px;
}

.enter-otp > div:first-child {
  display: flex;
  justify-content: space-around !important;
  align-items: center !important;
}

.enter-otp input {
  width: 30px !important;
  height: 40px !important;
  border: 1px solid #8bcad9;
  font-size: 1em;
}
.enter-otp a {
}
