.campaignDetailCard {
  display: flex;
  flex-direction: column;
  width: 450px;
  height: auto;
  margin: auto;
  padding: 2em 3em;
  margin-top: -10%;
  align-items: center;
  justify-content: space-evenly;
  gap: 1em;
  position: relative;
  backdrop-filter: blur(10px);
  border: 4px solid rgba(251, 133, 1, 1);
  border-radius: 35px;
  z-index: 5;
}

.campaignDetailCard > img {
  z-index: 2;
  width: 100%;
  height: 160px;
  background: #ffffff;
  box-shadow: 4px 8px 17px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  object-fit: contain;
}
.campaignDetailCard > ul,
.brandDetail > ul {
  margin-top: 1em;
  width: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
}

.brandDetail li,
.campaignDetailCard li {
  font-size: 16px;
  display: flex;
  align-items: center;
}

.brandDetail li > b {
  color: #fff;
}

.campaignDetailCard li > span,
.campaignDetailCard li > b,
.brandDetail li > span,
.brandDetail li > b {
  flex: 1;
}
.brandDetail span {
  color: #1e1e1e;
  font-weight: 700;
  margin-right: 1em;
}

.campaignDetailCard ul li::before,
.brandDetail ul li::before {
  content: '• ';
  color: #f8991d;
  font-size: 22px;
  margin-right: 1em;
}

.campaignDetailCard > ul > li > span {
  margin-right: 0.5em;
}
.campaignDetailCard > ul > li > b {
  color: #219ebc;
}

.campaignDetailInfoCard {
  box-shadow: 10px 7px 32px 0px #00000040;
  border-radius: 35px;
  padding: 2em;
  height: 80vh;
}

.capaign-details-banner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.capaign-details-banner h5 {
  color: white;
  font-weight: '700';
  font-size: 4em;
}
.create-nft-section {
  width: 90%;
  margin: auto;
  padding-top: 100px;
  padding-bottom: 50px;
}
.create-nft-left {
  position: relative;
  z-index: 50;
}
.create-nft-left-container {
  background-image: url(../../assets/browse-campaign-details/rectangle.png);
  max-width: 80%;
  background-size: cover;
  margin: auto;
  padding: 50px;
  padding-top: 70px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
}
.ellips1 {
  position: absolute;
  top: 7%;
  left: 0%;
  width: 70%;
  z-index: -50;
}
.ellips2 {
  position: absolute;
  top: -5%;
  right: 5%;
  width: 87%;
  z-index: -50;
}
.create-nft-left-container h2 {
  text-transform: capitalize !important;
}
.create-nft-left-container ul {
  background: #219ebc;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 95%;
  margin: auto;
  padding: 15px 30px;
  margin-bottom: 20px;
}
.create-nft-left-container ul li {
  color: rgba(255, 255, 255, 0.75);
  letter-spacing: -0.02em;
  margin-bottom: 2px;
  list-style: none;
  font-size: 16px;
}
.create-nft-left-container ul li::before,
.reach-out-card ul li::before {
  content: '• ';
  color: #f8991d;
  font-size: 22px;
}
.create-nft-left-container ul li span {
  color: #f6f8fb;
  margin-right: 10px;
}
.img-gallary {
  width: 70%;
  margin: auto;
}
.img-gallary img {
  width: 100%;
}
.img-gallary > div {
  display: flex;
  margin-top: 15px;
}
.img-gallary > div > :nth-child(1) {
  padding-right: 10px;
}
.img-gallary > div > :nth-child(2) {
  padding-left: 10px;
}
.create-nft-right-container img {
  width: 90%;
  margin: auto;
  margin-bottom: 25px;
}
.create-nft-right-container button {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.reach-out-card {
  background: #ffffff;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 25px 40px;
  margin-top: 30px;
}
.reach-out-card ul {
  padding-bottom: 15px;
}
.reach-out-card ul li {
  color: rgba(0, 0, 0, 0.5);
  letter-spacing: -0.02em;
  list-style: none;
  font-weight: 600;
  font-size: 16px;
}
.reach-out-card ul li span {
  color: #000000;
  margin-right: 10px;
}
.why-support-us-section h2 {
  color: #219ebc !important;
  margin: 0px;
  margin-top: 50px;
}
.why-support-us-content {
  width: 80%;
  margin: auto;
}
.single-support-container {
  background: #ffffff;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 40px 20px;
  margin-bottom: 40px;
}
.why-support-us-content h4 {
  color: #219ebc !important;
  text-align: left !important;
  text-transform: capitalize !important;
  position: relative;
  margin-left: 15px;
}
.why-support-us-content h4::before {
  content: '';
  position: absolute;
  background: #f8991d;
  height: 25px;
  width: 4px;
  top: 0;
  left: -13px;
}
.why-support-us-content p {
  font-size: 14px;
  line-height: 131%;
  color: #023047;
  opacity: 0.75;
}
.single-support-container ul {
  padding: 0px;
}
.single-support-container ul li {
  color: #023047;
  list-style: none;
  margin-bottom: 5px;
}
.single-support-container ul li::before {
  content: '• ';
  color: #fce887;
  font-size: 22px;
}
.single-support-container ul li span {
  color: #f8991d;
}
.support-us-tabs {
  background: #023047;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 12px;
}
.support-us-tabs {
  color: #023047;
  background: #fff;
  font-size: 13px;
  border-radius: 30px;
  padding: 13px 60px;
  border-bottom: none !important;
}
.support-us-tabs {
  min-height: 0px;
  border: 1px solid #fff;
  border-radius: 30px;
  font-size: 13px;
  color: #fff;
  padding: 13px 60px;
}
.support-us-tabs {
  width: 100% !important;
  align-items: center;
}
.support-us-tabs {
  justify-content: space-around !important;
}
.support-us-tabs {
  background-color: transparent;
}

.milestoneCardContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.milestoneNumber {
  display: flex;
  gap: 1em;
}
.milestoneNumber > h5 {
  margin: 0;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #023047;
}

.milestoneNumber > span {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  /* identical to box height, or 29px */

  display: flex;
  align-items: center;

  color: #023047;

  opacity: 0.9;
}
.milestoneHeading {
  margin: 0 !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #219ebc !important;
}
.milestoneCardContainer > p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #023047;
  opacity: 0.9;
}
.kpiContainer {
  background: #219ebc;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 30px;
}

.kpiContainer > h2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  margin: 0 !important;
  color: #fce887 !important;
}

.kpiContainer > p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  /* or 29px */

  display: flex;
  align-items: center;

  color: #ffffff !important;
}

@media screen and (max-width: 992px) {
  .create-nft-left-container {
    max-width: 90%;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .create-nft-left-container ul {
    padding: 5px 10px;
    margin-bottom: 10px;
  }
  .create-nft-left-container ul li {
    font-size: 12px;
    line-height: 18px;
  }
  .capaign-details-banner img {
    width: 70%;
    height: auto;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .why-support-us-content {
    width: 90%;
  }
  .support-us-tabs {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .support-us-tabs,
  .support-us-tabs {
    font-size: 8px;
    padding: 5px;
  }
  .support-us-tabs {
    align-items: center;
  }
}
