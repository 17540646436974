.toast-message-box{
    background: #fff;
    box-shadow: 0px 0px 10px 3px #e1e1e1;
    width: 25%;
    margin-bottom: 35px;
    margin-top: 30px;
    margin-left: 30px;
    border-radius: 8px;
    padding: 10px 20px;
    position: relative;
    left: 70%;
}
.box-border{
    position: absolute;
    width: 4px;
    height: 50px;
    top: 7px;
    left: 7px;

}
.toast-messages-container{
    display: flex;
    align-items: center;
}
.toast-message{
    margin-top: 5px;
}
.toast-message h6{
    font-weight: 600;
    font-size: 14px;
    margin: 0;
    margin-bottom: -3px;
    padding: 0;
    color: #4b4b4b;
}
.toast-message span{
    font-size: 12px;
    color: #929292;
    font-weight: 500;
}
.toast-messages-container{}
.toast-message{}
.toast-message h6{}
.toast-message span{} 
.toast-icon{
    font-size: 26px;
    margin-right: 12px;
}
.close-icon{
    position: absolute;
    right: 16px;
    font-size: 14px;
    font-weight: 700;
    color: #717171;
    cursor: pointer;
}