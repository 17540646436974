.faq-content-section {
  background: #f3f5f9;
  padding-top: 100px;
  padding-bottom: 50px;
}
.faq-accordion {
  width: 80%;
  margin: auto;
}
.faq-accordion svg {
  color: #f8991d;
  font-size: 1.6rem;
}
.faq-accordion .MuiAccordion-root {
  margin-bottom: 15px;
}
.faq-accordion
  div.MuiAccordionSummary-content.css-1betqn-MuiAccordionSummary-content
  p.MuiTypography-root.MuiTypography-body1.css-ahj2mt-MuiTypography-root,
.faq-accordion h4,
.short-history-container h4 {
  text-transform: capitalize !important;
  color: #219ebc;
  font-weight: 600;
  font-size: 18px !important;
}
.faq-accordion h4 {
  position: relative;
  margin-top: 25px;
  margin-left: 20px;
}
.faq-accordion h4::before,
.sub-content h4::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 20px;
  background: #f8991d;
  top: 1px;
  left: -20px;
}
.faq-accordion
  div.MuiAccordionDetails-root.css-15v22id-MuiAccordionDetails-root
  p.MuiTypography-root.MuiTypography-body1.css-ahj2mt-MuiTypography-root,
.faq-accordion p,
.short-history-container p {
  font-weight: 500;
  line-height: 146%;
  color: #023047;
  opacity: 0.75;
  text-transform: none;
  font-size: 16px !important;
}
.faq-accordion .css-19fv6u5-MuiPaper-root-MuiAccordion-root {
  box-shadow: none;
  border-radius: 50px !important;
  padding: 20px 50px;
}

.faq-accordion .css-19fv6u5-MuiPaper-root-MuiAccordion-root::before {
  background-color: transparent;
}

@media screen and (max-width: 768px) {
  .faq-accordion
    div.MuiAccordionSummary-content.css-1betqn-MuiAccordionSummary-content
    p.MuiTypography-root.MuiTypography-body1.css-ahj2mt-MuiTypography-root,
  .faq-accordion h4,
  .short-history-container h4 {
    font-size: 15px !important;
  }
  .faq-accordion
    div.MuiAccordionDetails-root.css-15v22id-MuiAccordionDetails-root
    p.MuiTypography-root.MuiTypography-body1.css-ahj2mt-MuiTypography-root,
  .faq-accordion p,
  .short-history-container p {
    font-size: 14px !important;
  }
  .faq-accordion {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .faq-accordion .css-19fv6u5-MuiPaper-root-MuiAccordion-root {
    padding: 10px;
  }
}
