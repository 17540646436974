.dashboardButtonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  gap: 1rem;
  margin-bottom: 1.2em;
}

.dashboardButtonContainer > button {
  background-color: #6243d2;
  padding: 0.8rem 1rem;
  border: none;
  color: #fff;
  min-width: 250px;
  border-radius: 10px;
  box-shadow: 0px 14px 10px #ccc;
  transition: all 0.2s;
}
.dashboardButtonContainer > button:hover {
  background-color: #7852ff;
}
.myCollectionContainer {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 1em;
}

.myCollectionContainer > div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.myCollectionContainer > div:first-child > div {
  display: flex;
  gap: 1em;
}
.myCollectionContainer > div:first-child > div > button {
  border: none;
  outline: none;
  border-radius: 7px;
  width: 100px;
  background-color: #fff;
  padding: 0.5em;
}

.collectionImgs {
  display: flex;
  gap: 1em;
  font-size: 14px;
}

.collectionImgs > div {
  display: flex;
  align-items: center;
  gap: 3em;
  padding: 1em;
  width: 80%;
  overflow: hidden;
  padding: 0.5em 2em 2.5em 2em;
  background-color: transparent;
}
.collectionImgs span {
  align-self: flex-end;
  color: #000;
  text-decoration: none;
  font-weight: 600;
  padding-bottom: 2.5em;
}
.collectionCardImage {
  height: 400px;
  width: 100%;
  object-fit: contain;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
  font-size: 12px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #219ebc;
  color: white;
  font-size: 14px;
}
