@media screen and (max-width: 768px) {
    .launch-button{
        font-size: 20px !important;
    }
    .register-button{
        text-align: center !important;
    }
    .register-button button{
        font-size: 18px !important;
    }
}