.registerContainer {
  width: 100%;
  background-color: white;
  display: flex;
}
.registerLeft,
.registerRight {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.registerLeft {
  background-image: url('../../assets/loginRegistration.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.registerRight {
  overflow-y: auto;
}

.registerLeft > div,
.registerRight > div {
  width: 65%;
  display: flex;
  flex-direction: column;
}

.registerLeft > div {
  color: white;
  height: 80%;
  justify-content: space-around;
}

.registerLeft > div > div:first-child > span {
  text-align: center;
  font-weight: 500;
  color: #023047;
  font-size: 1.5em;
  margin-top: 1em;
}

.registerLeft > div > div:first-child {
  display: flex;
  flex-direction: column;
}

.registerLeft > div > div:last-child {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.registerLeft > div > div:last-child > img {
  width: 50px;
}

.registerRight > div {
  background-color: #d9d9d93a;
  width: 85%;
  height: 85%;
  padding: 3em 4em;
  border-radius: 10px;
}

.registerRight > div > h2 {
  text-align: center;
  font-weight: 700;
}

.registerRight > div > h5 {
  margin: 0.7em 0;
}

.registerRight > div > h5 > a {
  color: #fb8501;
  cursor: pointer;
  text-decoration: none;
}

.registrationDetailContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
}

.registrationDetailContainer > button,
.registrationDetailContainer > div:nth-child(3),
.registrationDetailContainer > div:nth-child(4),
.registrationDetailContainer > div:nth-child(7)
/* .registrationDetailContainer > div:nth-child(9),
.registrationDetailContainer > div:nth-child(10),
.registrationDetailContainer > div:nth-child(11)  */ {
  grid-column: 1 / span 2;
}

/* .registrationDetailContainer > div:nth-child(4),
.registrationDetailContainer > div:nth-child(5) {
  flex-direction: row;
} */

.registerRight > div > div > div:nth-child(8) > label > span {
  cursor: pointer;
  color: #2680eb;
}

.registerRight > div > div > button {
  padding: 0.7em 1.3em;
  background: #fb8501;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  color: white;
  font-weight: 700;
  border-radius: 10px;
  font-size: 1.2em;
}

.registerLine {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em 0;
}

.registerLine::before {
  content: 'OR';
  position: absolute;
  background-color: white;
  z-index: 1;
  padding: 0.4em 1em;
  font-weight: 700;
}

.registerLine > hr {
  width: 100%;
}

.registerBtnContainer {
  display: flex;
  gap: 1em;
}
.registerBtnContainer > div {
  display: flex;
  align-items: center;
  border: 1px solid #707070;
  padding: 0.5em 0em 0.5em 0.5em;
  flex: 1;
  cursor: pointer;
}
.registerBtnContainer > div > img {
  width: 25px;
}
.registerBtnContainer > div > button {
  border: none;
  background-color: transparent;
  font-size: 12px;
}

@media screen and (max-width: 1350px) {
  .registerRight > div {
    width: 90%;
  }
}
@media screen and (max-width: 1260px) {
  .registerRight > div {
    padding: 1.5em;
  }
}
@media screen and (max-width: 1080px) {
  .registerLeft {
    width: 50%;
  }
  .registerRight {
    width: 50%;
  }
  .registerLeft > div {
    width: 90%;
  }
  .registerLeft > div > div:first-child > span {
    font-size: 1.3em;
    margin-top: 1em;
  }
  .registerLeft > div > div:last-child > h3 {
    font-size: 1.4em;
  }
  .registerLeft > div > div:last-child > img {
    width: 45px;
  }
  .registerRight {
    align-items: unset;
  }
  .registerRight > div {
    margin: 1.5em 0;
  }
  .registerRight > div > div {
    grid-template-columns: 1fr;
  }
  .registerRight > div > div > button,
  .registerRight > div > div > div:nth-child(3),
  .registerRight > div > div > div:nth-child(8),
  .registerRight > div > div > div:nth-child(9),
  .registerRight > div > div > div:nth-child(10) {
    grid-column: 1;
  }
}

@media screen and (max-width: 980px) {
  .registerBtnContainer {
    flex-direction: column;
  }
  .registerLeft > div > div:first-child > span {
    font-size: 1.1em;
    margin-top: 1em;
  }
  .registerLeft > div > div:last-child > h3 {
    font-size: 1.2em;
  }
  .registerLeft > div > div:last-child > img {
    width: 35px;
  }
}

@media screen and (max-width: 768px) {
  .registerLeft {
    display: none;
  }
}
