.influence-container {
  height: 160vh;
  overflow: hidden;
}
.influence-clip-bg {
  background: #ffb703;
  transform: rotate(-106.24deg);
  top: 32%;
}
.infulence-form-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
  width: 70%;
  margin: auto;
}
.infulence-btn-div {
  text-align: center;
  margin-right: 0px;
  margin-top: 45px;
}
.infulence-form-section {
  padding-bottom: 80px;
}
.file-icon {
  color: #e67336;
  background: rgba(230, 115, 54, 0.35);
  border-radius: 4px;
  padding: 12px;
  font-size: 40px;
  margin-top: 15px;
  margin-bottom: 10px;
}
.file-icon {
  margin-right: 10px;
}
.checkbox-input input {
  height: 11px;
  color: #fff;
  background: orange;
  border-radius: 32px;
  margin-right: 6px;
  margin-left: 15px;
  margin-bottom: 10px;
}
.checkbox-input input:focus {
  outline: none;
}
.yes,
.no {
  color: #aeaeae !important;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .reg-form-grid {
    display: block !important;
    width: 100% !important;
  }
  .reg-form-container {
    width: 90% !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .half-colored-container {
    position: relative;
  }
  .half-colored-container::after {
    content: "";
    position: absolute;
    background-color: transparent !important;
  }
  .form-grid {
    margin-top: 10px;
  }
  .influence-container {
    height: 180vh;
    overflow: hidden;
  }

  .infulence-form-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .influenceone {
    order: 1;
  }
  .influencetwo {
    order: 2;
  }
  .influencethree {
    order: 3;
  }
  .influencefour {
    order: 4;
  }
  .influencefive {
    order: 5;
  }
  .influencesix {
    order: 6;
  }
  .influenceseven {
    order: 7;
  }
  .influenceeight {
    order: 8;
  }
  .infulence-submit-btn {
    width: 80%;
  }
}
