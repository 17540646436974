.nft-hub-container {
  overflow: hidden;
}
.nft-banner-section {
  height: 80vh;
  background-image: url('../../assets/images/banner.jpg');
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}
.nft-banner-section h2 {
  font-weight: 700;
  font-size: 70px;
  line-height: 100px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  width: 50%;
  margin: auto;
  text-align: center;
  padding-top: 200px;
}
.welcome-to-hub-section {
  width: 90%;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 50px;
}
.welcome-hub-left-sec h2 {
  font-weight: 700;
  font-size: 61px;
  line-height: 85px;
  color: #219ebc;
}
.welcome-hub-left-sec p {
  font-weight: 500;
  font-size: 30px;
  line-height: 44px;
  color: #219ebc;
}
.welcome-hub-right-sec img {
  width: 100%;
  margin-left: 25px;
}
.nft-hub-card-section {
  width: 90%;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 80px;
}
.single-hub-card {
  background: #fce887;
  box-shadow: 20px 14px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 30px 30px;
  text-align: center;
  margin-right: 10px;
  margin-left: 10px;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.card-border {
  position: absolute;
  top: -4%;
  border: 1px solid #023047;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  left: -5%;
}
.single-hub-card p {
  font-weight: 400;
  font-size: 18px;
  line-height: 41px;
  color: #023047;
}
.hub-qa-section {
  width: 80%;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 20px 14px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 30px 50px;
  margin-bottom: 80px;
}
.qa-img img {
  width: 100%;
}
.qa-content h4 {
  font-weight: 600;
  font-size: 40px;
  line-height: 54px;
  color: #023047;
  position: relative;
}
.qa-content h4::before {
  content: '';
  position: absolute;
  width: 5px;
  height: 30px;
  top: 12px;
  background: #f8991d;
  left: -25px;
}
.qa-content p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  color: #219ebc;
}
.qa-align-items {
  align-items: center;
}
.nft-movement-content {
  width: 70%;
  margin: 0 auto;
  background: #219ebc;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 80px;
  padding-bottom: 80px;
  margin-bottom: 80px;
}
.nft-movement-content h2 {
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 2em;
  color: #ffffff;
}
.nft-movement-content p {
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  width: 60%;
  margin: auto;
}
.nft-collection-section {
  position: relative;
  background: #1f789f;
  padding: 50px 20px;
}
.elips1 {
  position: absolute;
  top: 18%;
  left: 0%;
  height: 12%;
}
.elips2 {
  position: absolute;
  top: 15%;
  left: 0px;
  height: 12%;
}
.elips3 {
  position: absolute;
  top: 60%;
  left: 0%;
  height: 12%;
}
.elips4 {
  position: absolute;
  top: 57%;
  left: 0%;
  height: 12%;
}
.elips5 {
  position: absolute;
  top: 36%;
  right: 0%;
  height: 12%;
}
.elips6 {
  position: absolute;
  top: 33%;
  right: 0%;
  height: 12%;
}
.elips7 {
  position: absolute;
  top: 82%;
  right: 0%;
  height: 12%;
}
.elips8 {
  position: absolute;
  top: 79%;
  right: 0%;
  height: 12%;
}
.nft-collection-section h2 {
  font-weight: 700;
  font-size: 60px;
  line-height: 70px;
  text-align: center;
  letter-spacing: -1.5px;
  color: #ffffff;
  padding-top: 30px;
  padding-bottom: 10px;
  text-align: center;
}
.nft-collection-section p {
  font-weight: 400;
  font-size: 42px;
  line-height: 50px;
  color: #ffffff;
  width: 70%;
  margin: 0 auto;
  padding-bottom: 20px;
  text-align: center;
}
.collection-content-wrap {
  padding: 0 50px;
  padding-bottom: 50px;
}
.single-collection-content {
  padding-top: 80px;
}
.collection-content {
  text-align: left;
}
.collection-content p {
  font-weight: 400;
  font-size: 40px;
  line-height: 54px;
  color: #f4f6f9;
  text-align: left;
  width: 100%;
  position: relative;
  margin-left: 20px;
}
.collection-content p::before {
  content: '';
  position: absolute;
  width: 5px;
  height: 30px;
  background: #f8991d;
  top: 20%;
  left: -18px;
}

.collection-content span {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #f4f6f9;
  width: 90%;
  display: inline-block;
}
.inner-collection-content {
  display: flex;
  align-items: center;
}
.inner-collection-content img {
  width: 100px;
  height: 100px;
  margin-right: 25px;
}
.collection-img {
  padding-right: 70px;
}
.collection-img img {
  width: 100%;
}
.nfthub-signup-container {
  background-color: #f2f2f2;
  width: 80%;
  padding-left: 100px;
  padding-right: 100px;
  height: 700px;
  position: relative;
  margin-bottom: 100px;
}
.nfthub-signup-container h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 140%;
  color: #023047;
  padding-bottom: 5px;
}
.nfthub-signup-container p {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #219ebc;
  width: 60%;
}
.nft-signup-heading {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.nft-signup-form {
  background: #fce887;
  border-radius: 55px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding: 50px;
  width: 40%;
  left: 70%;
}
.nft-signup-form h2 {
  font-weight: 700;
  font-size: 42px;
  line-height: 140%;
  text-align: center;
  color: #023047;
  margin-top: -15px;
}
.input-box {
  padding-top: 20px;
}
.input-box label {
  font-weight: 400;
  font-size: 11px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #023047;
  padding-bottom: 10px;
  display: inline-block;
}
.input-box input {
  border-radius: 3.52799px;
  width: 100%;
  border: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #000;
  padding: 12px;
}
::placeholder {
  color: #aeaeae;
  font-size: 13px;
}
.nft-signup-form-submit {
  text-align: center;
  padding-top: 50px;
}
.nft-signup-form-submit button {
  background: #219ebc;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #ffffff;
  border: none;
  padding: 10px 100px;
  border-radius: 7px;
}
.priview-gallary-section {
  width: 70%;
  margin: auto;
  background: #ffffff;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 50px 0;
  position: relative;
  overflow: hidden;
  margin-bottom: 100px;
}
.priview-gallary-section h2 {
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  color: #023047;
}
.priview-gallary-section p {
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: #023047;
  width: 67%;
  margin: 0 auto;
}
.collection-alignment {
  align-items: center;
}
.preview-border {
  position: absolute;
  top: -4%;
  border: 1px solid #fb8501;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  left: -2%;
}

/* Responsive Css */
@media screen and (max-width: 1300px) {
  .nft-banner-section {
    height: auto;
    background-size: cover;
  }
  .nft-banner-section h2 {
    padding-top: 200px;
    font-size: 50px;
    line-height: 60px;
    width: 80%;
    padding-bottom: 200px;
  }
  .single-hub-card {
    height: 700px;
    margin-bottom: 30px;
  }
  .priview-gallary-section {
    width: 90%;
    margin-bottom: 50px;
  }
  .priview-gallary-section h2 {
    font-size: 22px;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 20px;
  }
  .priview-gallary-section p {
    font-size: 20px;
    line-height: 30px;
    width: 90%;
  }
  .nft-signup-form h2 {
    font-weight: 700;
    font-size: 24px;
  }
  .nft-signup-form-submit button {
    font-size: 9px;
    padding: 6px 80px;
  }
  .collection-content span {
    font-size: 18px;
    line-height: 28px;
  }
  .nft-collection-section h2 {
    font-size: 40px;
    line-height: 50px;
  }
  .nft-collection-section p {
    font-size: 30px;
    line-height: 37px;
  }
  .single-hub-card {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 992px) {
  .nft-banner-section {
    height: auto;
    background-size: cover;
  }
  .nft-banner-section h2 {
    padding-top: 150px;
    font-size: 40px;
    line-height: 50px;
    width: 80%;
    padding-bottom: 150px;
  }
  .welcome-hub-left-sec h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .welcome-hub-left-sec p {
    font-size: 22px;
    line-height: 30px;
  }
  .welcome-hub-right-sec img {
    margin-top: 15px;
  }
  .single-hub-card {
    height: 400px;
    margin-bottom: 30px;
    padding: 20px 25px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .single-hub-card p {
    font-size: 16px;
    line-height: 30px;
  }
  .qa-content p {
    font-size: 16px;
    line-height: 30px;
  }
  .nft-movement-content h2 {
    font-size: 22px;
  }
  .nft-movement-content p {
    font-size: 18px;
  }
  .nft-collection-section h2 {
    font-size: 32px;
    line-height: 40px;
    padding-top: 0;
  }
  .nft-collection-section p {
    font-size: 19px;
    line-height: 27px;
    width: 80%;
  }
  .nfthub-signup-container h2 {
    font-size: 24px;
  }
  .nfthub-signup-container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    height: 800px;
  }
  .priview-gallary-section {
    width: 90%;
    margin-bottom: 50px;
  }
  .priview-gallary-section h2 {
    font-size: 22px;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 20px;
  }
  .priview-gallary-section p {
    font-size: 20px;
    line-height: 30px;
    width: 90%;
  }
  .preview-border {
    left: -4%;
  }
  .nfthub-signup-container p {
    font-size: 16px;
    line-height: 25px;
    width: 100%;
  }
  .nft-signup-form {
    left: 0%;
    top: 5%;
    width: 100%;
    padding: 30px;
    position: relative;
    border-radius: 10px !important;
  }
  .nft-signup-form h2 {
    font-size: 18px !important;
  }
  .nft-signup-heading {
    position: static;
    padding-top: 250px;
  }
  .single-collection-content {
    padding-top: 15px;
  }
  .collection-content-wrap {
    padding: 0 15px;
  }
  .inner-collection-content img {
    width: 50px;
    height: 50px;
  }
  .collection-content span {
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 20px;
  }
  .collection-img {
    padding-right: 0px;
  }
  .elips1 {
    height: 6%;
    top: 14%;
  }
  .elips2 {
    height: 6%;
    top: 12%;
  }
  .elips3 {
    height: 6%;
    top: 52%;
  }
  .elips4 {
    height: 6%;
    top: 50%;
  }
  .elips5 {
    height: 6%;
    top: 43%;
  }
  .elips6 {
    height: 6%;
    top: 41%;
  }
  .collection-content p::before {
    top: 0%;
  }
  .single-collection-content {
    margin-top: 40px;
  }
}
@media screen and (max-width: 600px) {
  .nft-banner-section {
    height: auto;
    background-size: cover;
  }
  .nft-banner-section h2 {
    padding-top: 70px;
    font-size: 24px;
    line-height: 30px;
    width: 80%;
    padding-bottom: 70px;
  }
  .welcome-hub-left-sec h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .welcome-hub-left-sec p {
    font-size: 22px;
    line-height: 30px;
  }
  .welcome-hub-right-sec img {
    margin-top: 15px;
  }
  .single-hub-card {
    height: 400px;
    margin-bottom: 30px;
  }
  .single-hub-card p {
    font-size: 14px;
    line-height: 30px;
  }
  .qa-content p {
    font-size: 14px;
    line-height: 30px;
  }
  .nft-movement-content h2 {
    font-size: 18px;
  }
  .nft-movement-content p {
    font-size: 14px;
  }
  .nft-collection-section h2 {
    font-size: 32px;
    line-height: 40px;
    padding-top: 0;
  }
  .nft-collection-section p {
    font-size: 19px;
    line-height: 27px;
    width: 80%;
  }
  .nfthub-signup-container h2 {
    font-size: 24px;
  }
  .nfthub-signup-container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    height: 800px;
  }
  .priview-gallary-section {
    width: 90%;
    margin-bottom: 50px;
  }
  .priview-gallary-section h2 {
    font-size: 22px;
    line-height: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .priview-gallary-section p {
    font-size: 20px;
    line-height: 30px;
    width: 90%;
  }
  .preview-border {
    left: -4%;
  }
  .nfthub-signup-container p {
    font-size: 16px;
    line-height: 25px;
    width: 100%;
  }
  .nft-signup-form {
    left: 0%;
    top: 5%;
    width: 100%;
    padding: 30px;
    position: relative;
    border-radius: 10px !important;
  }
  .nft-signup-form h2 {
    font-size: 18px !important;
  }
  .nft-signup-heading {
    position: static;
    padding-top: 250px;
  }
  .single-collection-content {
    padding-top: 15px;
  }
  .collection-content-wrap {
    padding: 0 15px;
  }
  .inner-collection-content img {
    width: 50px;
    height: 50px;
  }
  .collection-content span {
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 20px;
  }
  .collection-img {
    padding-right: 0px;
  }
  .elips1 {
    height: 6%;
    top: 14%;
  }
  .elips2 {
    height: 6%;
    top: 12%;
  }
  .elips3 {
    height: 6%;
    top: 52%;
  }
  .elips4 {
    height: 6%;
    top: 50%;
  }
  .elips5 {
    height: 6%;
    top: 43%;
  }
  .elips6 {
    height: 6%;
    top: 41%;
  }
  .elips7 {
    height: 6%;
    top: 91%;
  }
  .elips8 {
    height: 6%;
    top: 89%;
  }
  .collection-content p::before {
    top: 0%;
  }
  .single-collection-content {
    margin-top: 40px;
  }
}
