.reporting-section {
  width: 80%;
  margin: auto;
  padding-top: 150px;
  padding-bottom: 40px;
}
.reporting-details-container {
  margin-top: 80px;
  background: #ffffff;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 50px 30px;
}
.reporting-details-container > div,
.milestones-container {
  background: #ffffff;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 15px;
}
.report-container h4 {
  text-transform: uppercase !important;
  text-align: center !important;
  position: static !important;
}
.reporting-details-container > div > h4 {
  text-transform: capitalize;
  text-align: left;
  position: relative;
  margin: 0;
  margin-bottom: 10px;
  margin-left: 20px;
}
.reporting-details-container > div > h4::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 20px;
  background: #f8991d;
  top: 4px;
  left: -20px;
}
.reporting-details-container > div > p,
.submit-reports p {
  color: #023047;
  opacity: 0.75;
}
.report-container {
  background: #ffffff;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 30px !important;
}
.report-container > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.report-container > div > div {
  background: #f8991d;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 200px;
  height: 80px;
  padding: 12px 12px;
}
.report-container h5 {
  color: #fff;
  font-weight: 600;
  margin: 0;
  margin-bottom: 4px;
}
.report-container span,
.milestone-number span {
  color: #023047;
  opacity: 0.75;
}
.milestones-container {
  margin-bottom: 30px !important;
}
.milestones-container h5 {
  color: #219ebc;
  font-weight: 600;
}
.milestones-container p {
  margin-bottom: 20px;
}
.milestones-container > div:nth-child(3) {
  padding-top: 20px;
  border-top: 1px solid #d9d9d9;
}

.milestone-number {
  display: flex;
}
.kpi {
  background: #219ebc;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 15px;
}
.kpi:last-child {
  margin-bottom: 30px;
}
.kpi h5 {
  color: #fce887;
  margin: 0;
}
.kpi p {
  color: #ffffff;
}
.milestone-number h6 {
  color: #023047;
  font-weight: 700;
  margin-right: 12px;
}
.portfolio-gallery-container {
  background: #f7f7f7;
  padding-bottom: 50px;
}
.portfolio-gallery-container h2 {
  text-align: left;
  text-transform: capitalize;
  padding-top: 50px;
  margin-bottom: 20px;
}
.portfolio-gallery-container > div:first-child {
  width: 80%;
  margin: auto;
}
.portfolio-browse-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.portfolio-browse-filter svg {
  font-size: 20px;
}
.portfolio-browse-filter h6 {
  color: #000000;
  font-weight: 600;
  margin-left: 10px;
}
.portfolio-browse-filter > div {
  background: #ffffff;
  border: 2px solid #f4f4f4;
  border-radius: 11px;
  display: flex;
  justify-content: space-between;
  padding: 6px 30px;
}
.portfolio-gallery {
  width: 90%;
  margin: auto;
  margin-top: 40px;
}
.single-portfolio-gallery {
  background: #ffffff;
  box-shadow: 10px 13px 26px rgba(0, 0, 0, 0.15);
  border-radius: 28px;
}
.single-portfolio-gallery div {
  padding: 15px 20px;
}
.single-portfolio-gallery img {
  width: 100%;
}
.single-portfolio-gallery h4 {
  text-align: left;
  text-transform: capitalize;
  color: #219ebc;
  margin: 0px;
  margin-bottom: 2px;
}
.single-portfolio-gallery span,
.single-portfolio-gallery p {
  color: #959796;
}
.single-portfolio-gallery p {
  padding-top: 15px;
}
.submit-report-container {
  width: 80%;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 297px 119px rgba(27, 41, 62, 0.01),
    0px 167px 100px rgba(27, 41, 62, 0.05), 0px 74px 74px rgba(27, 41, 62, 0.09),
    0px 19px 41px rgba(27, 41, 62, 0.1), 0px 0px 0px rgba(27, 41, 62, 0.1);
  border-radius: 20px;
  padding: 30px 50px;
  position: relative;
  z-index: 100;
  margin-top: 80px;
  margin-bottom: 80px;
}
.submit-reports {
  box-shadow: none;
  padding: 0;
}
.submit-report-form input:focus,
textarea:focus {
  outline: none;
}
.submit-report-form h5 {
  color: #219ebc;
  font-weight: 700;
  margin: 0;
}
.submit-report-form input[type="date"] {
  color: #aeaeae;
}
@media screen and (max-width: 768px) {
  .report-container > div {
    display: block;
  }
  .report-container > div > div {
    width: 100%;
    margin-bottom: 15px;
  }
  .portfolio-browse-filter {
    display: block;
  }
  .portfolio-browse-filter > div {
    margin-bottom: 10px;
  }
  .submit-report-container {
    width: 95%;
    padding: 30px 30px;
    margin-top: 30px;
  }
  .report-Container h2 {
    margin-top: 20px;
  }
  .report-Container::after {
    background-color: transparent !important;
  }
}
