.influencer-detail-intro {
  width: 90%;
  margin: auto;
}
.company-logo {
  width: 30%;
  margin-top: 100px;
  margin-bottom: 50px;
  text-align: center;
}
.details-intro-container {
  background: #ffffff;
  box-shadow: -8px 5px 37px 27px rgba(0, 0, 0, 0.1);
  padding: 40px 60px;
}
.details-intro1 {
  text-align: center;
}
.details-intro1 img {
  width: 80%;
}
.details-intro1 > div {
  width: 70%;
  margin: auto;
  background: #219ebc;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 30px;
  margin-top: 30px;
}
.details-intro1 span {
  color: #fff;
  font-size: 12px;
}
.details-intro-container span svg {
  background: #023047;
  padding: 5px;
  font-size: 22px;
  border-radius: 50%;
}

.details-intro2 h2 {
  color: #219ebc;
  text-transform: capitalize;
  text-align: left;
  margin: 0;
  position: relative;
  margin-left: 20px;
}
.details-intro2 h2::before {
  content: "";
  position: absolute;
  top: 8px;
  background: #f8991d;
  left: -20px;
  width: 7px;
  height: 33px;
}
.details-intro2 h5 {
  margin: 0;
  letter-spacing: 0.2px;
  color: #023047;
  opacity: 0.75;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 22px;
}

.details-intro2 span {
  letter-spacing: 0.2px;
  color: #023047;
  opacity: 0.75;
  font-weight: 600;
  font-size: 16px;
}
.intro-images {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo-img {
  width: 100% !important;
  margin: 0px !important;
  background: #f6f8fb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 6px;
}
.intro-images {
  margin-top: 20px;
}
.intro-images > div:nth-child(1) > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.intro-images > div:nth-child(1) > div > img {
  width: 100% !important;
  margin-left: 5px;
  margin-right: 5px;
}

.intro-images > div:nth-child(2) > img {
  width: 100% !important;
}
.details-intro2 h6 {
  margin: 0;
  margin-top: 0px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0.2px;
  color: #219ebc;
  opacity: 0.75;
  font-size: 18px;
  margin-top: 15px;
}
.details-intro2 h6 span {
  text-transform: uppercase;
  color: #023047;
  opacity: 0.75;
  font-weight: 400;
}
.details-intro3 h5 {
  margin: 0;
  font-weight: 700;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: #219ebc;
  opacity: 0.75;
  margin-bottom: 10px;
}
.details-intro3 p {
  font-size: 16px;
  letter-spacing: 0.2px;
  color: #023047;
  opacity: 0.75;
  line-height: 24px;
}
.influencer-about-container {
  width: 90%;
  margin: auto;
  margin-top: 80px;
  background: #ffffff;
  box-shadow: 10px 7px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 50px;
}
.influencer-about-container h4 {
  text-align: left;
  color: #219ebc;
  position: relative;
  margin-left: 20px;
}
.influencer-about-container h4::before {
  content: "";
  position: absolute;
  top: 2px;
  background: #f8991d;
  left: -20px;
  width: 5px;
  height: 26px;
}
.influencer-about-container p {
  color: #023047;
  opacity: 0.75;
  font-size: 17px;
  line-height: 27px;
}
.influencer-showcase-section {
  background: #219ebc;
  margin-top: 50px;
  padding-top: 50px;
}
.influencer-showcase-container {
  width: 90%;
  margin: auto;
}
.showcase-filter-tabs h4 {
  color: #fff;
}
.showcase-filter-tabs {
  background: #219ebc;
  box-shadow: 7px 7px 38px 13px rgba(0, 0, 0, 0.25);
  padding: 30px 50px;
}
.showcase-filter-tab-panel img {
  width: 100%;
  padding: 15px;
}
.showcase-filter-tabs .css-h0q0iv-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #023047 !important;
}
.showcase-filter-tabs .css-h0q0iv-MuiButtonBase-root-MuiTab-root {
  color: #ffffff;
  text-transform: capitalize;
  font-weight: 600;
}
.showcase-filter-tabs .css-1aquho2-MuiTabs-indicator {
  height: 0px;
}
.showcase-filter-tabs .css-heg063-MuiTabs-flexContainer {
  justify-content: space-between;
  display: block;
  text-align: center;
}
.tabs-border {
  border-top: 2px solid #15677a;
}
.showcase-filter-tabs select {
  background: transparent;
  border: none;
  border: 1px solid #fff;
  color: #fff;
  padding: 8px 50px;
  border-radius: 10px;
  margin-left: 60px;
}
.showcase-filter-tabs select option {
  padding: 0;
  margin: 0;
  color: #000;
}
.showcase-filter-tabs select:focus {
  outline: none;
}
.tabs-icon svg {
  color: #3c3c3c;
}
@media screen and (max-width: 768px) {
  .showcase-filter-tabs {
    padding: 15px 0px;
  }
  .showcase-filter-tabs .css-h0q0iv-MuiButtonBase-root-MuiTab-root {
    font-size: 10px;
    padding-left: 3px !important;
    padding-right: 3px !important;
    min-width: 0px !important;
    overflow: hidden;
  }
  .showcase-filter-tabs
    .css-h0q0iv-MuiButtonBase-root-MuiTab-root
    > .MuiTab-iconWrapper {
    margin-right: 3px;
  }
  .influencer-showcase-container {
    width: 99%;
  }
  .showcase-filter-tabs select {
    display: block;
    text-align: center;
    font-size: 12px;
    padding: 5px 30px;
    margin-left: 0px;
    margin-left: 35%;
  }
  .single-showcase-tab {
    display: block !important;
    text-align: center !important;
    margin-left: 35% !important;
    margin-top: -40px !important;
  }
}
