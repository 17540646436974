.info-hub-banner {
  position: relative;
}
.info-hub-banner h1 {
  color: #fff !important;
  position: relative;
  z-index: 50;
}
.info-search-input {
  position: relative;
  z-index: 50;
  text-align: center;
  width: 55%;
  color: rgba(2, 48, 71, 0.5);
}
.info-search-input input {
  width: 100%;
  background: #f6f8fb;
  border-radius: 50px;
  border: none;
  line-height: 28px;
  padding: 4px 25px;
}
.info-search-input input::placeholder {
  color: rgba(2, 48, 71, 0.6);
  font-weight: 700;
  font-size: 16px;
}
.search-icon {
  position: absolute;
  top: 10px;
  right: 20px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  color: #219ebc;
  font-weight: 700;
  font-size: 16px;
}
.filter-tabpanel {
  width: 70%;
  margin: auto;
  margin-top: 130px;
}
.filter-tabpanel img {
  width: 100%;
}
.tabs-label {
  margin-top: -48px;
}
.tabs-label .MuiTabs-flexContainer {
  justify-content: center;
}
.tabs-label .Mui-selected {
  color: #fff !important;
  text-transform: capitalize !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
  background: #023047 !important;
  border-radius: 7px 7px 0px 0px !important;
}
.tabs-label button {
  background: #fff !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
  color: #023047 !important;
  text-transform: capitalize !important;
  border-radius: 7px 7px 0px 0px !important;
  margin: 0px 15px !important;
}
.single-infohub {
  border: 6px solid #f8991d;
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
}
.single-infohub img {
  border-radius: 15px 15px 0px 0px;
  height: 180px;
}
.single-infohub h4 {
  color: #219ebc;
  text-transform: capitalize;
  text-align: left;
  position: relative;
  margin-left: 5px;
  padding-top: 30px;
  height: 130px;
  padding-left: 15px;
  padding-right: 15px;
}
.single-infohub h4::before {
  content: '';
  position: absolute;
  width: 5px;
  height: 28px;
  background: #f8991d;
  top: 30px;
  left: 0px;
}

@media screen and (max-width: 992px) {
  .info-search-input {
    width: 80%;
    margin-top: -50px;
  }
  .info-hub-banner {
    height: 50vh !important;
    background-position: center !important;
  }
  .tabs-label .info-search-input input::placeholder {
    font-size: 12px;
  }
  .tabs-label .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    max-width: 48px !important;
    min-width: 24px !important;
    font-size: 8px !important;
    margin: 0px 4px !important;
  }
}
