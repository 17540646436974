.App {
  overflow-x: hidden;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.slick-slide > div {
  height: 100% !important;
}

.slick-arrow {
  width: 100px !important;
  height: 100px !important;
  z-index: 1 !important;
  font-size: 60px !important;
}

.slick-arrow::before {
  font-family: 'slick';
  font-size: 60px !important;
  line-height: 1;
  opacity: 1 !important;
  color: #fb8501 !important;
}
@media screen and (max-width: 786px) {
  .typo-text-align, html body div#root div.sc-gsnTZi.gxrinJ div.MuiBox-root.css-1rsdsug div.MuiBox-root.css-tqss3w div.MuiBox-root.css-1wk39o2 div div p.MuiTypography-root.MuiTypography-body1.css-1mejmsd-MuiTypography-root, html body div#root div.MuiBox-root.css-15lrlm1 div.MuiBox-root.css-tqss3w div.MuiBox-root.css-1s5xjlp div div div p.MuiTypography-root.MuiTypography-body1.css-1mejmsd-MuiTypography-root{
    text-align: left !important;
  }
  .type-text-size{
    font-size: 18px !important;
  }
  .text-fonts{
    font-size: 24px !important;
  }
}

.globalDescription {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important; /* number of lines to show */
  line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
}

.singleLineText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
