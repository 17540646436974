.startup-intro{
    width: 80%;
    margin: auto;
}
.startup-process-section{
    background: #F3F6FB;
}
.process-section-bg{
    background-image: url('../../assets/accelerator-startup/process-background.png');
    padding-top: 80px;
    padding-bottom: 80px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.startup-process-container{
    width: 90%;
    margin: auto;
}
.startup-process-container h2{
    
} 
.phase-one{

}
.phase-heading{
    background: #FB8501;
    border-radius: 10px;
    width: 200px;
    height: 55px;
    margin: auto;
    color: #fff;
    text-align: center;
    padding-top: 7px;
    margin-bottom: 40px;
    box-shadow: 0px 6px 10px 0px #b9b9b9;
}
.phase-heading h6{
    margin: 0;
    font-weight: 600;
    padding-bottom: 3px;
}
.phase-heading span{
    text-transform: capitalize;
    font-weight: 300;
}
.phases-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.phase-two-content{
    justify-content: space-evenly;
}
.single-phase-card{
    background: #FFFFFF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 200px;
    height: 100px;
    box-shadow: 1px 30px 25px -8px #d7d7d7;
}
.single-phase-card img{
    width: 15%;
}
.single-phase-card h5{
    font-size: 16px;
    font-weight: 700;
}
.phase-three-content{
    text-align: center;
    position: relative;
    width: 40%;
    margin: auto;
}
.phase-three-content img{
    width: 100%;
}
.pahse-three-heading{
    position: absolute;
    left: 55%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.pahse-three-heading h4{
    margin: 0;
    color: #fff;
}
.pahse-three-heading h6{
    color: #023047;
    font-weight: 600;
    margin: 0;
    font-size: 13px;
}
.apply-here-container{
    background-image: url("../../assets/accelerator-startup/apply-here-bg.png");
    padding-bottom: 50px;
    position: relative;
}
.apply-here-container::before{
    content: '';
    position: absolute;
    top: 0; right: 0; left: 0; bottom: 0;
    background: rgba(33, 158, 188, 0.9);
}
.apply-here-container p{
    text-align: center;
font-size: 26px;
color: #fff;
margin-bottom: 30px;
position: relative;
z-index: 50;
padding-top: 50px
}
.apply-here-container h2{
    position: relative;
    z-index: 50;
}

@media screen and (max-width: 768px) {
    .startup-intro > div{
        width: 100% !important;
        margin-top: 0px !important;
    }
    .startup-intro > div > div:nth-child(3){
        padding:20px !important;
        margin-top: -20px !important;
        margin-bottom: -20px !important;
    }
    .startup-intro p{
        text-align: left !important;
    }
    .phases-content{
        display: block;
    }
    .single-phase-card{
        display: block;
        text-align: center;
        width: 300px;
        height: 130px;
        margin: auto;
        padding-top: 10px;
        margin-bottom: 30px;
    }
    .phase-two-card{
        padding-top: 20px;
        height: 100px;
    }
    .single-phase-card img{
        width: 10%;
        margin-bottom: 10px;
    }
    .phase-three-content{
        width: 100%;
    }
    .pahse-three-heading h6{
        font-size: 10px;
    }
    .apply-here-container p{
        font-size: 22px;
    }
}